import React, { useState, useEffect, useContext } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import moment from 'moment-timezone';
import CalendarMonthOutlinedIcon from '@mui/icons-material/CalendarMonthOutlined';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import Container from '../../components/HOC/Container/Container';
import { useLocation, useNavigate } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { Skeleton, TablePagination, Tooltip, Typography } from '@mui/material';
import BorderColorIcon from '@mui/icons-material/BorderColor';
import orderService from '../../../services/order-services';
import DropDown from '../../components/DropDown/DropDown';
import { addOrders, changeLabel, chooseEvent, setLastPage, setPageDetails } from '../../../redux/orderReducer';
import authService from '../../../services/auth-services';
import AccessDenied from '../../components/AccessDenied/AccessDenied';
import { fetchEvents } from '../../../redux/eventReducer';
import { getAuctionReport, setAuctionPage, setCustomDateRange, setSelectedReport, setSelectedDateRange, setSoldStatusFilter, getEventSummery, clearError, clearSummary, clearAuctionReport, setOrderSearchValue, setOrderSearchBy, getTotalOrdersSum } from '../../../redux/reportReducer';
import AuthContext from '../../../store/auth-context';
import style from './OrdersAndReports.module.css'
import DatePickerComponent from '../../components/DatePicker/DatePicker';
import Input from '../../components/Input/Input';
import { checkNetworkError, setDefaultEndDate, setDefaultStartDate } from '../../../utils/util-functions';
import auctionServices from '../../../services/auction-services';
import Modal from '../../components/Modal/Modal';
import LoadingModal from '../../components/LoadingModal/LoadingModal';
import Alert from '../../components/Alert/Alert';
import CSVDownload from '../../components/CSVDownload';
import { fetchDonorUserReport,clearDonorReport } from '../../../redux/donorReportReducer';
import { setPagination, setPaginationLimit, setPaginationPage, setTableBody, setTableHeading, setDetails } from '../../../redux/orderReportModalTableReducer';
import TableButton from '../../components/TableButton/TableButton';
import RemoveRedEyeOutlinedIcon from '@mui/icons-material/RemoveRedEyeOutlined';
import { fethcMarketPlaceOrStore } from '../../../redux/marketPlaceReducer';
import constants from '../../../constants/en';
import { clearItemSalesReport, fetchItemSalesReportRedux } from '../../../redux/itemSalesReport';
import ItemSalesReportTable from '../../components/ItemSalesReportTable/ItemSalesReportTable';
import {appendSkus, getXrefCategoryData, itemData } from '../../../utils/ordersAndReport.utils';
import { clearCategorySalesReport, fetchCategorySalesReportRedux } from '../../../redux/categorySalesReport';
import CategorySalesReportTable from '../../components/CategorySalesReportTable/CategorySalesReportTable';
import VariantCombination from '../../components/VariantCombination/VariantCombination';
import SearchInput from '../../components/SearchInput/SearchInput';



const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        fontWeight: 600,
        borderBottom: "1px solid black"
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
    },
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}));

function OrderAndReportsMarketStore() {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const ctx = useContext(AuthContext)

    const [options, setOptions] = useState([{ label: '', value: '' }]);
    const [modalArray, setModalArray] = useState([]);
    const [modal, setModal] = useState(false)
    const [modalType, setModalType] = useState('')
    const [access, setAccess] = useState(true);
    const [orderLoading, setOrderLoading] = useState(false)
    const [pageLoading, setPageLoading] = useState(false) //Loading when checking  for access
    const [csvLoading, setCsvLoading] = useState(false)
    const [csvData, setCsvData] = useState(null)
    const [orderError, setOrderError] = useState(false)
    const {marketPlaces, loading, totalMarketPlace} = useSelector(state => state.marketPlace)   
    // const { events, loading } = useSelector(state => state.userEvents)
    const { orders, totalOrders, selectedEvent, page, limit, lastPage } = useSelector(state => state.orderDetails);
    const { auctionReport,
        totalAuctions,
        auctionPage,
        auctionLimit,
        selectedReport,
        selectedDateRange,
        customDateRange,
        soldStatusFilter,
        eventSummery,
        auctionLoading,
        summeryLoading,
        error: auctionError,
        orderSearchValue,
        orderSearchBy
    } = useSelector(state => state.report)
    const {paginationLimit, paginationPage, totalData, tableHeading, tableBody, tableType} = useSelector(state => state.orderModalTable);
    const [customDate, setCustomDate] = useState({ start: null, end: null })
    const {itemSalesHeading} = useSelector(state => state.itemSales)
    const { categorySalesHeading, cateogrySalesData } = useSelector(state => state.categorySales)

    const [donorPage, setDonorPage] = useState(1)
    const [donorLimit, setDonorLimit] = useState(20)
    const { donorData, totalDonors, donorReportLoading } = useSelector(state => state.donorReport)
    const [donorUserId, setDonorUserId] = useState('')
    const [modalLoading, setModalLoading] = useState(false)
    const [hasPermission,setHasPermission] = useState(true);
    const [godAccess, setGodAccess] = useState(false);
    const [successAlert, setSuccessAlert] = useState({message:'', isOpen:false, alertType:'alert', confirmFunction:()=>null, confirmMetaData:null});
    // const [donorReport, setDonorReport] = useState([])

    const [searchClicked, setSearchClicked] = useState(false)

    const location = useLocation()
    let marketplace = false;
    let store = false;
    if(location.pathname.includes('/market-place')) {
        marketplace = true;
    }
    if(location.pathname.includes('/store')) {
        store = true;
    }

    useEffect(()=>{
        if(selectedEvent.value)
        {
            selectedEvent.value && access && dispatch(getTotalOrdersSum({auctionId:selectedEvent.value, startDate:customDateRange.start,endDate:customDateRange.end,showDelivered:soldStatusFilter.showDelivered,showFailed:soldStatusFilter.showFailed,orderSearchValue:orderSearchValue, orderSearchBy:orderSearchBy,marketplace:marketplace, store:store}))
        }
    },[selectedEvent.value,customDateRange,soldStatusFilter,searchClicked])

    useEffect(() => {
        let forCharity;
        let label;
        if(location.pathname.includes('/market-place')){
          forCharity = 'forMarketPlace'
          label = 'a marketplace'
        }
        else {
          forCharity = 'charity_store'
          label = 'a store'
        }
        dispatch(fethcMarketPlaceOrStore({user_id:ctx.user.id, page: null, limit: null, forCharity:forCharity}))
        checkMarketOrStorePermission()
        if(lastPage !== 'edit-order') {
            dispatch(changeLabel(label))
        }
        dispatch(setLastPage({lastPage:''}))
    }, [location.pathname])

    useEffect(() => {
        const data = marketPlaces?.map((event) => ({ label: event.title, value: event.id }))
        setOptions(data)
    }, [marketPlaces])

    useEffect(() => {
        if (!selectedEvent.value) return
        if (!access) return
        switch (selectedReport.value) {
            case 'order':
                fetchOrders(selectedEvent.value, soldStatusFilter.showDelivered, customDateRange.start, customDateRange.end, soldStatusFilter.showFailed)
                break;
            case 'auction':
                fetchAuctionReport(selectedEvent.value);
                break;
            case 'donor':
                fetchDonorReport(selectedEvent.value, customDateRange.start, customDateRange.end)
                break;
            case 'item-sales':
                fetchItemSalesReport(selectedEvent.value);
                break;
            case 'category-sales':
                fetchCategorySalesReport(selectedEvent.value);
                break;
            default:
                ;
        }
    }, [limit, page, auctionPage, auctionLimit, access, selectedEvent.value, selectedReport.value, soldStatusFilter, customDateRange, donorPage, donorLimit,location.pathname, searchClicked])

    useEffect(() => {
        switch (selectedDateRange.value) {
            case '7d':
                dispatch(setCustomDateRange({ start: setDefaultStartDate(moment(new Date()).subtract(7, 'days')), end: setDefaultStartDate(new Date()) }))
                break;
            case '24h':
                dispatch(setCustomDateRange({ start: setDefaultEndDate(moment(new Date()).subtract(24, 'hours')), end: setDefaultEndDate(new Date()) }));
                break;
            case '30d':
                dispatch(setCustomDateRange({ start: setDefaultEndDate(moment(new Date()).subtract(30, 'days')), end: setDefaultEndDate(new Date()) }));
                break;
            case 'lifetime':
                dispatch(setCustomDateRange({ start: setDefaultEndDate(moment(new Date()).subtract(10, 'years')), end: setDefaultEndDate(new Date()) }));
                break;
            default:
                ;
        }
        dispatch(setPageDetails({ page: 1 }))
        dispatch(setAuctionPage({ page: 1 }))
        setDonorPage(1)
    }, [selectedDateRange])

    const setCustomDateChange = () => {
        customDate.end && dispatch(setCustomDateRange({ end: setDefaultEndDate(customDate.end) }))
        customDate.start && dispatch(setCustomDateRange({ start: setDefaultStartDate(customDate.start) }))
    }
    useEffect(() => {
        if (!selectedEvent.value) return
        checkPermission()
        dispatch(setPageDetails({ page: 1 }))
        dispatch(setAuctionPage({ page: 1 }))
    }, [selectedEvent.value])
    useEffect(() => {
        if(!selectedEvent.value) {
            dispatch(clearSummary())
            dispatch(clearAuctionReport())
            dispatch(clearDonorReport())
            dispatch(clearItemSalesReport())
            dispatch(clearCategorySalesReport())
         }
        selectedEvent.value && access && dispatch(getEventSummery({auctionId:selectedEvent.value, marketplace:marketplace, store:store,startDate:customDateRange.start,endDate:customDateRange.end,showDelivered:soldStatusFilter.showDelivered,showFailed:soldStatusFilter.showFailed,orderSearchValue:orderSearchValue, orderSearchBy:orderSearchBy}))
    }, [selectedEvent.value, access,customDateRange,soldStatusFilter,searchClicked])

    const checkPermission = async () => {
        setPageLoading(true)
        const permission = await authService.checkPermissions(11, selectedEvent.value)
        if (!permission.access) {
            setAccess(false)
            return
        } else {
            setAccess(true)
        }
        setPageLoading(false)
    }

    const checkGodPermission = async() => {
        try{
            setPageLoading(true)
            const permission = await authService.checkLimitedPermissions(15);
            setGodAccess(permission?.access);
            setPageLoading(false)
        }catch(error) {
            setGodAccess(false);
            setPageLoading(false)
        }
      }

      useEffect(() => {
        checkGodPermission()
      },[])

    const checkMarketOrStorePermission = async () => {
      try {
            let permission_id;
        if(location.pathname.includes('/market-place')){
          permission_id = 17
        }
        else {
          permission_id = 18
        }
        setModalLoading(true)

        const permission = await authService.checkLimitedPermissions(permission_id)
 
        if (!permission.access) {
            setHasPermission(false)
        } else {
            setHasPermission(true)
        }
         setModalLoading(false)
      }catch (error) {
          setModalLoading(false)
          setOrderError(true)
        //   if(!checkNetworkError(error)) {
        //     setOrderError(true)
        //   }
      }
    }
    const fetchOrders = async (auctionId, showDelivered, startDate, endDate, showFailed) => {
        try {
            setOrderLoading(true)
            let data = await orderService.getAllOrder(auctionId, page, limit, showDelivered, startDate, endDate, showFailed, null, marketplace, store, orderSearchValue, orderSearchBy)
            dispatch(addOrders({ orders: data?.rows, totalOrders: data?.count }))
            setOrderLoading(false)
        } catch (error) {
            setOrderLoading(false)
            setOrderError(true)
        }

    }

    const fetchAuctionReport = async (auctionId) => {
        dispatch(getAuctionReport(auctionId))
    }

    const fetchDonorReport = async (auctionId, startDate, endDate) => {
        dispatch(fetchDonorUserReport({ auctionId, page: donorPage, limit: donorLimit, startDate, endDate, marketplace:marketplace, store:store, orderSearchValue, orderSearchBy }))
    }

    const fetchItemSalesReport = async(auctionId) => {
        dispatch(fetchItemSalesReportRedux({auctionId: auctionId, page: auctionPage, limit: auctionLimit, pageType: marketplace ? 'marketplace' : 'store', orderSearchValue, orderSearchBy}))
    }

    const fetchCategorySalesReport = async(auctionId) => {
        dispatch(fetchCategorySalesReportRedux({auctionId: auctionId, page: auctionPage, limit: auctionLimit, pageType: marketplace ? 'marketplace' : 'store', orderSearchValue, orderSearchBy}))
    }

    const orderStatus = (status) => {
        switch (status) {
            case '0':
                status = 'Order Placed'
                break;
            case '1':
                status = 'Order Placed'
                break;
            case '3':
                status = 'Shipped'
                break;
            case '6':
                status = 'Shipped'
                break;
            case '7':
                status = 'Out For Delivery'
                break;
            case '8':
                status = 'Delivered'
                break;
            case '9':
                status = 'Packed'
                break;
            case '12':
                status = 'In Transit'
                break;
            case '14':
                status = 'Returned'
                break;
            case '15':
                status = 'Address Check Failed'
                break;
            case null:
                status = 'Payment Failed'
                break;
            default:
                status = 'Unknown'
                break;
        }
        return status

    }
    ///options for dropdowns
    const reportOptions =location.pathname.includes('/market-place') 
                        ? [{ label: 'Order Report', value: 'order' }, { label: 'Auction Report', value: 'auction' }, { label: 'Donor Report', value: 'donor' }, {label: 'Item Sales', value:'item-sales'}, , {label: 'Category Sales', value:'category-sales'}]
                        :[{ label: 'Order Report', value: 'order' }, { label: 'Donor Report', value: 'donor' }, {label: 'Item Sales', value:'item-sales'}, , {label: 'Category Sales', value:'category-sales'}]
    const dateRangeOptions = [{ label: 'Last 7 Days ', value: '7d' }, { label: 'Last 24 hour', value: '24h' },
    { label: 'Last 30 Days', value: '30d' }, { label: 'Lifetime', value: 'lifetime' }, { label: 'Custom', value: 'custom' }]


    const handleCheck = (e) => {
        dispatch(setPageDetails({ page: 1 }))
        dispatch(setAuctionPage({ page: 1 }))
        dispatch(setSoldStatusFilter({ [e.target.name]: e.target.checked }))
        //not allowing to keep both showSold and showUnsold unchecked at same time.
        // if (!e.target.checked) {
        //     if (e.target.name === 'showSold' && !soldStatusFilter.showUnsold) {
        //         dispatch(setSoldStatusFilter({ showUnsold: true }))
        //     }
        //     if (e.target.name === 'showUnsold' && !soldStatusFilter.showSold) {
        //         dispatch(setSoldStatusFilter({ showSold: true }))
        //     }
        // }
    }

    const clearErrors = () => {
        dispatch(clearError()) //auction error
        setOrderError(false)  //order error
    }

    const handleOrderCsvData = async (auctionId, showDelivered, startDate, endDate, showFailed) => {
        if (!auctionId) return
        try {
            setCsvLoading(true)
            let data = await orderService.getAllOrder(auctionId, page, limit, showDelivered, startDate, endDate, showFailed, true, marketplace, store)
            let newList = [['Order number', 'Product', 'SKU', 'Variant Group', 'First Name', 'Last Name', 'Email', 'Status', 'Purchase date', 'Price(Excl. tax and charges)', 'Proceeds', 'Quantity']]
            data.rows.forEach(row => {
                newList.push([`BG_${row.user_order?.id}`, row.auction_item?.name, row.product_sku_id,
                row?.variant_combination?.fullValue ? row?.variant_combination?.fullValue : 'NA',
                row?.user_order?.user?.first_name, row?.user_order?.user?.last_name, row?.user_order?.user?.email,
                row.user_order.payment_status === 'success' ? orderStatus(row.user_order?.status) : `Payment ${row.user_order.payment_status}`,
                moment(row.user_order?.created).format('MM/DD/YYYY'),
                // row.auction_item?.price == 0 ? (parseFloat(row.auction_item?.store_item?.price) * row.quantity_bought) : row.user_order?.subtotal,
                getTotalItemOrderPrice(row),
                // row?.donor_rev_share_price,
                getOrderProceed(row),
                row.quantity_bought])
            });
            setCsvData(newList)
            setCsvLoading(false)
        } catch (error) {
            setCsvLoading(false)
        }

    }

    const handleAuctionCsvData = async (auctionId) => {
        if (!auctionId) return
        try {
            setCsvLoading(true)
            let data = await auctionServices.getAuctionReport(auctionId, auctionPage, auctionLimit, customDateRange, soldStatusFilter, 'showAll')
            let newList = [['Order number', 'Product', 'SKU', 'Status', 'Purchase date', 'Bids', 'Winning bids / Buy Now Price']]
            data.data.rows.forEach(row => {
                let xref = row.user_order_auction_item_xrefs[0]
                newList.push([xref ? `BG_${xref?.user_order.id}` : '--', row.name, row.sku,
                xref?.user_order.payment_status === 'success' ? orderStatus(xref?.user_order.status) : (xref?.user_order.payment_status ?
                    `Payment ${xref?.user_order.payment_status}` : ( moment(row.end_date) < moment() ) ? 'Ended' : 'Running '),
                xref ? moment(xref?.user_order?.created).format('MM/DD/YYYY') : '--', row.bids.length, xref ? xref?.user_order.subtotal : row.bids[row.bids.length - 1]?.bid_amount
                ])
            });
            setCsvData(newList)
            setCsvLoading(false)
        } catch (error) {
            setCsvLoading(false)
        }

    }

    const handleDonorCsvData = async (auctionId, startDate, endDate) => {
        if (!auctionId) return
        try {
            setCsvLoading(true)
            let response = await orderService.getDonorOrder(auctionId, donorPage, donorLimit, startDate, endDate, marketplace, store)
            let newList = [['Email', 'First Name', 'Last Name', 'Number of Orders', 'Order Total']]
            response?.data?.rows.forEach(row => {
                newList.push([row?.user?.email, row?.user?.first_name, row?.user?.last_name,
                row?.total_orders, row?.total_order_amount])
            });
            setCsvData(newList)
            setCsvLoading(false)
        } catch (error) {
            setCsvLoading(false)
        }

    }


    const handleItemSalesCsvData = async(auctionId) => {
        if(!auctionId) return;
        try {
            const data = await auctionServices.getItemSalesReport(auctionId, null, null, marketplace ? "marketplace" : "store")
            const newList=[itemSalesHeading];
            data?.data?.forEach((row) => {
                const skuString = appendSkus(row?.user_order_auction_item_xrefs, row?.variants, row?.options)
                newList.push([
                    row?.name,
                    // row?.user_order_auction_item_xrefs[0]?.product_sku_id,
                    skuString,
                    itemData(row).quantity,
                    +row?.user_order_auction_item_xrefs[0]?.total_value,
                    `$${+row?.user_order_auction_item_xrefs[0]?.total_value * +itemData(row).price}`
                ])
            })
            setCsvData(newList)
            setCsvLoading(false)
        } catch (error) {
            setCsvLoading(false)
        }
    }

    const handleCategorySalesCsvData = async(auctionId) => {
        if(!auctionId) return;
        try {
            // const data = await auctionServices.getCategorySalesReport(auctionId, null, null, marketplace ? "marketplace" : "store")
            const newList=[categorySalesHeading];
            cateogrySalesData?.forEach((row) => {
                newList.push([
                    getXrefCategoryData(row)?.name,
                    row?.total_items_sold,
                    `$${row?.total_sales}`
                ])
            })
            setCsvData(newList)
            setCsvLoading(false)
        } catch (error) {
            setCsvLoading(false)
        }
    }


    useEffect(() => { setCsvData(null) }, [csvData])

    const downLoadCsv = () => {
        switch (selectedReport.value) {
            case 'order':
                handleOrderCsvData(selectedEvent.value, soldStatusFilter.showDelivered, customDateRange.start, customDateRange.end)
                break;
            case 'auction':
                handleAuctionCsvData(selectedEvent.value)
                break;
            case 'donor':
                handleDonorCsvData(selectedEvent.value);
                break;
            case 'item-sales':
                handleItemSalesCsvData(selectedEvent.value);
                break;
            case 'category-sales':
                handleCategorySalesCsvData(selectedEvent.value);
                break;
            default:
                ;
        }
    }

    const changeReport = (e) => {
        dispatch(setPageDetails({ page: 1 }))
        dispatch(setAuctionPage({ page: 1 }))
        dispatch(setSelectedReport({ label: e.label, value: e.value }))
    }
    let USDollar = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
    });

    useEffect(() => {
        if(tableType === 'Order Details') handleDonorOrders(donorUserId, paginationPage, paginationLimit);
    },[paginationPage, paginationLimit])

    const handleDonorOrders = async(userId='', paginationPage, paginationLimit) => {
        setDonorUserId(userId)
        setModal(true)
        setModalLoading(true)
        try {
            const data = await orderService.getDonorOrderDetails(selectedEvent?.value, userId, paginationPage, paginationLimit, marketplace, store);
            dispatch(setDetails({tableType: 'Order Details', tableHeading:['Product Name', 'Product SKU', 'Ordered Date', 'Price'], tableBody:data?.data}))
            dispatch(setPagination({totalData: data?.count, paginationPage:data?.page, paginationLimit:data?.limit}))
            setModalLoading(false)
        }catch(error) {
            setModalLoading(false)
            setModal(false)
        }
    }

    const handleBidsTable = (bids=[]) => {
        dispatch(setDetails({tableType: 'Bids', tableHeading:['First Name', 'Last Name', 'Email', 'Bid Date', 'Bid Amount'], tableBody:bids}))
        setModal(true);
    }

    const getLabel = () => {
        return location.pathname.includes('/market-place') ? 'a Marketplace': 'a Store'
    }

    const getOrderProceed = (row) => {
        if(marketplace) {
            return row?.donor_rev_share_price
        }
        else {
            return row?.cause_rev_share_price
        }
    }

    const closeAlert = (clearMetaData=true) => {
        if(clearMetaData) {
            setSuccessAlert({message:'', isOpen:false, alertType:'alert', confirmFunction:()=>null, confirmMetaData:null})
        }
        else {
            setSuccessAlert({...successAlert, message:'', isOpen:false, alertType:'alert', confirmFunction:()=>null})
        }
    }

    const handleEndAuction = async(itemId) => {
        try {
            closeAlert(false)
            if(!itemId) return;
            setOrderLoading(true)
            const response = await auctionServices.endAuction(itemId)
            dispatch(getAuctionReport(selectedEvent.value))
            closeAlert()
            setOrderLoading(false)
            setSuccessAlert({message:response?.message, isOpen:true, alertType:'alert', confirmFunction:()=>null})
        }
        catch(error) {
            setOrderLoading(false)
            setSuccessAlert({message:constants.ADMIN_COPY_SOMETHING_WENT_WRONG, isOpen:true, alertType:'alert', confirmFunction:()=>null})
        }
    }

    const showEndAuctionAlert = (auctionItemId) => {
        setSuccessAlert({message:constants.ADMIN_COPY_END_AUCTION_ALERT, isOpen:true, alertType:'confirm', confirmFunction:handleEndAuction, confirmMetaData:auctionItemId})
    }

    const handleEditOrder = (row) => {
        if(row.auction_item?.auction?.market_place == '1' && !marketplace) {
            setSuccessAlert({message:constants.ADMIN_COPY_DONATED_ITEM_ALERT, isOpen:true, alertType:'alert'})
            return;
        }
        navigate(`/admin/edit-order/${row?.id}`)
    }

    
    const handleOrderSearchChange = (e) => {
        dispatch(setOrderSearchValue({orderSearchValue: e.target.value}))
    }

    const handleOrderSearchSubmit = (searchByValue) => {
        // e.preventDefault()
        // if(!orderSearchValue || !orderSearchValue?.trim()?.length) return;
        dispatch(setOrderSearchBy({orderSearchBy: searchByValue}))
        setSearchClicked(!searchClicked)
    }

    const handleOrderClearClick = () => {
        if(!orderSearchValue || !orderSearchValue?.trim()?.length) return;
        dispatch(setOrderSearchValue({orderSearchValue: ""}))
        setSearchClicked(!searchClicked)
    }

    function getTotalItemOrderPrice(row){
        const price = parseFloat(row?.bg_rev_share_price) + parseFloat(row?.donor_rev_share_price) + parseFloat(row?.cause_rev_share_price);
        return price;
    }

    return (
        <Container title='Orders'>
            {
                loading ? <LoadingModal isOpen={true} /> : !hasPermission ? <AccessDenied/> :
                 <>
                    <div className='event_header_container'>
                        <span className='calender_icon_container' style={{ marginRight: '90px', paddingTop: '32px' }}>
                            <CalendarMonthOutlinedIcon sx={{ fontSize: '20px', color: '#34b5e5' }} />
                            <p>Choose {getLabel()}</p>
                        </span>
                        <DropDown isSearchable onChange={(e) => dispatch(chooseEvent({ label: e.label, value: e.value }))} value={selectedEvent} options={options} />
                    </div>
                    <hr />
                    {(!access && selectedEvent.value) ? pageLoading ? <LoadingModal isOpen={true} /> : <div style={{ height: "300px" }}><AccessDenied /></div> :
                        <>

                            {!summeryLoading ? <div className={style.card_container}>
                                <span className={style.card_container_title}>{location.pathname.includes('/market-place') ? 'MarketPlace': 'Store'} Summary </span>
                                {(location.pathname.includes('/market-place')) && <div>
                                    <div className='d-flex justify-content-center align-items-center'>

                                        <p className={style.card_title} >Proceeds</p>
                                        <p >
                                            <Tooltip title={<Typography sx={{ fontSize: '0.85rem' }}>{'This is the estimate total of all earnings, including currently open auctions (before fees)'}</Typography>}>
                                                <InfoOutlinedIcon sx={{ color: '#495057c4', fontSize: '16px', marginLeft: "4px" }} />
                                            </Tooltip>
                                        </p>
                                    </div>

                                    <p style={{ margin: '15px' }}>{USDollar.format(+eventSummery.openAuctions.totalWinningBid + +eventSummery.sales.totalProceedsAmount)}</p>
                                    {/* <p style={{ margin: '15px' }}>{USDollar.format(+eventSummery.openAuctions.totalWinningBid + +eventSummery.sales.amount)}</p> */}
                                    {/* <p style={{ margin: '15px' }}>{USDollar.format(+eventSummery?.openAuctions?.totalWinningBid + +eventSummery?.sales?.proceeds)}</p> */}
                                </div>}
                                <div>
                                    <div className='d-flex justify-content-center align-items-center'>
                                        <p className={style.card_title} >Sales</p>
                                        <p >
                                            <Tooltip title={<Typography sx={{ fontSize: '0.85rem' }}>{(location.pathname.includes('/market-place')) ? 'Total of all completed orders, does not include currently open auctions (before fees)' : 'Total of all completed orders'}</Typography>}>
                                                <InfoOutlinedIcon sx={{ color: '#495057c4', fontSize: '16px', marginLeft: "4px" }} />
                                            </Tooltip>
                                        </p>
                                    </div>
                                    <p>{eventSummery.sales.orderCount} orders</p>
                                    {/* <p>{USDollar.format(eventSummery.sales.amount)}</p> */}
                                    <p>{USDollar.format(eventSummery.sales.totalProceedsAmount)}</p>
                                </div>
                                <div>
                                <div className='d-flex justify-content-center align-items-center'>
                                        <p className={style.card_title} >Total Amount</p>
                                        <p >
                                            <Tooltip title={<Typography sx={{ fontSize: '0.85rem' }}>{'Total of all orders price (excl. tax and charges) shown in the report'}</Typography>}>
                                                <InfoOutlinedIcon sx={{ color: '#495057c4', fontSize: '16px', marginLeft: "4px" }} />
                                            </Tooltip>
                                        </p>
                                    </div>
                                    <p>{eventSummery?.sales?.dynamicProceedsAmount?USDollar.format(eventSummery.sales.dynamicProceedsAmount):USDollar.format(0)}</p>
                                </div>
                                {(location.pathname.includes('/market-place')) && <div>
                                    <div className='d-flex justify-content-center align-items-center'>
                                        <p className={style.card_title}>Open Auctions</p>
                                        <p>
                                            <Tooltip title={<Typography sx={{ fontSize: '0.85rem' }}>{'Total estimated earnings from all currently open auctions (before fees)'}</Typography>}>
                                                <InfoOutlinedIcon sx={{ color: '#495057c4', fontSize: '16px', marginLeft: "4px" }} />
                                            </Tooltip>
                                        </p>
                                    </div>
                                    <p>{`${eventSummery.openAuctions.openAuctionCount} (${eventSummery.openAuctions.totalOpenBidCount} Bids)`}</p>
                                    <p>{USDollar.format(eventSummery.openAuctions.totalWinningBid)}</p>
                                </div>}

                            </div> : <div className={style.card_container}>
                                <div><Skeleton variant='rectangular' height={100} /></div>
                                <div><Skeleton variant='rectangular' height={100} /></div>
                                <div><Skeleton variant='rectangular' height={100} /></div>
                            </div>}

                            <Paper>
                                <div>
                                    <div className={style.dropdown_container} >
                                        <DropDown label='Choose Report' onChange={changeReport} options={reportOptions} value={selectedReport} />
                                        {
                                            !['item-sales', 'category-sales'].includes(selectedReport.value) &&
                                            <DropDown label='Date Range' options={dateRangeOptions} onChange={(e) => dispatch(setSelectedDateRange({ label: e.label, value: e.value }))} value={selectedDateRange} />
                                        }
                                    </div >
                                    <div className={style.checkbox_container}>
                                        <div className={style.checkbox_inner_container}>
                                            {(selectedReport.value === 'auction' && location.pathname.includes('/market-place')) && <>
                                                <Input type='checkbox' label='Show Sold' name='showSold' onChange={handleCheck} checked={soldStatusFilter.showSold}
                                                    style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: '10px', width: '100%' }} />
                                                <Input type='checkbox' label='Show Unsold' name='showUnsold' onChange={handleCheck} checked={soldStatusFilter.showUnsold}
                                                    style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: '10px', width: '100%' }} />
                                                {<Input type='checkbox' label='Show Running' name='showRunning' onChange={handleCheck} checked={soldStatusFilter.showRunning}
                                                    style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: '10px', width: '100%' }} />}
                                            </>}
                                            {selectedReport.value === 'order' && <>
                                                <Input type='checkbox' label='Show Delivered' name='showDelivered' onChange={handleCheck} checked={soldStatusFilter.showDelivered}
                                                    style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: '10px', width: '100%' }} />
                                                <Input type='checkbox' label='Show Failed Orders' name='showFailed' onChange={handleCheck} checked={soldStatusFilter.showFailed}
                                                    style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: '10px', width: '100%' }} />
                                            </>}
                                        </div>

                                        {selectedDateRange.value === 'custom' ? <div style={{ gap: '20px', width: '45%' }}>
                                            <DatePickerComponent minDateTime={moment(new Date()).subtract(11, 'years')} maxDateTime={new Date()} label='From date'
                                                onClose={setCustomDateChange} onChange={(value) => setCustomDate({ ...customDate, start: value })} date={customDateRange.start} />
                                            <DatePickerComponent minDateTime={moment(new Date()).subtract(11, 'years')} maxDateTime={new Date()} label='To date'
                                                onClose={setCustomDateChange} onChange={(value) => setCustomDate({ ...customDate, end: value })} date={customDateRange.end} />
                                        </div> :
                                            <button style={{ height: '38px', marginTop: '32px' }} className='aitem-modal-btn' onClick={downLoadCsv} >{csvLoading ? "Loading CSV..." : "Download CSV Data"}</button>
                                        }
                                    </div>
                                    <div className={style.csv_container}>

                                        {selectedDateRange.value === 'custom' && <button className='aitem-modal-btn' onClick={downLoadCsv} >{csvLoading ? "Loading CSV..." : "Download CSV Data"}</button>}
                                        {csvData && <CSVDownload filename={`${selectedEvent.label.replaceAll(' ', '_')}-${selectedReport.value}s-${moment(customDateRange.start).format('DD_MM_YY_hh:mm')}-${moment(customDateRange.end).format('DD_MM_YY_hh:mm')}.csv`}
                                            data={csvData} target="_blank" replace={'false'} />}
                                    </div>
                                </div>
                                <div className={`search-input ${style.orderSearchSection}`}>
                                  <SearchInput 
                                     placeholder="Search..." 
                                     name='orderSearchValue' 
                                     onChange={handleOrderSearchChange} 
                                     value={orderSearchValue}
                                     handleClearClick={handleOrderClearClick}
                                    //  handleSearchClick={handleOrderSearchSubmit}
                                    needSearchIcon={false}
                                    style={{"flex":1}}
                                   />
                                   {
                                    ['order', 'auction', 'auction-winner', 'item-sales'].includes(selectedReport.value) &&
                                    <button onClick={() => handleOrderSearchSubmit('item')} className='aitem-modal-btn'>Search By Item</button>
                                   }
                                   {
                                    ['category-sales'].includes(selectedReport.value) &&
                                    <button onClick={() => handleOrderSearchSubmit('category')} className='aitem-modal-btn'>Search By Category</button>
                                   }
                                   {
                                    ['order', 'donor', 'auction-winner', 'bidders'].includes(selectedReport.value) &&
                                     <button onClick={() => handleOrderSearchSubmit('user')} className='aitem-modal-btn  aitem-white-button'>Search By Buyer</button>
                                   }
                                 </div>
                                {selectedReport.value === 'order' && <div>
                                    {
                                        orderLoading ? <LoadingModal style={{ position: 'relative', width: '100%', marginLeft: '0px', minHeight: '300px', backgroundColor: 'white' }} isOpen={true} /> : orders?.length ? <Paper>
                                            <TableContainer>
                                                <Table sx={{ minWidth: 700 }} aria-label="customized table">
                                                    <TableHead>
                                                        <TableRow>
                                                            <StyledTableCell >Order Number</StyledTableCell>
                                                            <StyledTableCell>Product</StyledTableCell>
                                                            <StyledTableCell>SKU</StyledTableCell>
                                                            <StyledTableCell>First Name</StyledTableCell>
                                                            <StyledTableCell>Last Name</StyledTableCell>
                                                            <StyledTableCell>Email</StyledTableCell>
                                                            <StyledTableCell>Status</StyledTableCell>
                                                            <StyledTableCell>Purchase Date</StyledTableCell>
                                                            {/* <StyledTableCell>Tracking url</StyledTableCell> */}
                                                            <StyledTableCell sx={{ fontSize: 12 }}>Price (excl. tax and charges)</StyledTableCell>
                                                            <StyledTableCell sx={{ fontSize: 12 }}>Proceeds</StyledTableCell>
                                                            <StyledTableCell>Quantity</StyledTableCell>
                                                            <StyledTableCell>Manage</StyledTableCell>
                                                        </TableRow>
                                                    </TableHead>
                                                    <TableBody>
                                                        {orders?.map((row, i) => {
                                                            return <StyledTableRow key={row.id}>
                                                                <StyledTableCell>BG_{row.user_order?.id}</StyledTableCell>
                                                                <StyledTableCell>{row.auction_item?.name}</StyledTableCell>
                                                                <StyledTableCell>
                                                                    <div className={style.sku_col}>
                                                                        <span>{row.product_sku_id}</span>
                                                                        {
                                                                            row?.variant_combination &&
                                                                            <VariantCombination combiantionKeys={Object.keys(row?.variant_combination?.fullObject)} combiantionValues={Object.values(row?.variant_combination?.fullObject)} />
                                                                        }
                                                                    </div>
                                                                </StyledTableCell>
                                                                <StyledTableCell>{row?.user_order?.user?.first_name}</StyledTableCell>
                                                                <StyledTableCell>{row?.user_order?.user?.last_name}</StyledTableCell>
                                                                <StyledTableCell>{row?.user_order?.user?.email}</StyledTableCell>
                                                                <StyledTableCell>{row.user_order.payment_status === 'success' ? orderStatus(row.user_order?.status) : `Payment ${row.user_order.payment_status}`}</StyledTableCell>
                                                                <StyledTableCell>{moment(row.user_order?.created).format('MM/DD/YYYY')}</StyledTableCell>
                                                                {/* <StyledTableCell>{}</StyledTableCell> */}
                                                                <StyledTableCell>{USDollar.format(getTotalItemOrderPrice(row))}</StyledTableCell>
                                                                <StyledTableCell>{USDollar.format(getOrderProceed(row))}</StyledTableCell>
                                                                <StyledTableCell>{row.quantity_bought}</StyledTableCell>

                                                                <StyledTableCell>
                                                                    {row.user_order.payment_status === 'success' && row.user_order?.status !== '8' &&
                                                                        // <div onClick={() => navigate(`/admin/edit-order/${row.id}`)} className='table-btn'> <BorderColorIcon sx={{ fontSize: "16px", color: "#5EABD6" }} />
                                                                        //     <span style={{ color: "#5EABD6", fontWeight: "600" }}>Edit</span>
                                                                        // </div>
                                                                        <TableButton onClick={() => handleEditOrder(row)} Icon={BorderColorIcon} />
                                                                    }
                                                                </StyledTableCell>

                                                            </StyledTableRow>
                                                        })}
                                                    </TableBody>
                                                </Table>
                                            </TableContainer>
                                            <TablePagination
                                                rowsPerPageOptions={[10, 20, 25, 50, 100]}
                                                component="div"
                                                count={totalOrders}
                                                rowsPerPage={limit}
                                                page={page - 1}
                                                onPageChange={(e, newPage) => dispatch(setPageDetails({ page: newPage + 1 }))}
                                                onRowsPerPageChange={(e) => { dispatch(setPageDetails({ page: 1, limit: +e.target.value })) }}
                                            />
                                        </Paper> : <Paper sx={{ height: '300px', textAlign: 'center', paddingY: '140px' }}>
                                            {selectedEvent.value ? <p>No order to show</p> : <p>Please choose {getLabel()}</p>}</Paper>
                                    }
                                </div>}
                                {selectedReport.value === 'auction' && <div>
                                    {
                                        (orderLoading || auctionLoading) ? <LoadingModal style={{ position: 'relative', width: '100%', marginLeft: '0px', minHeight: '300px', backgroundColor: 'white' }} isOpen={true} /> : auctionReport?.length ? <Paper>
                                            <TableContainer>
                                                <Table sx={{ minWidth: 700 }} aria-label="customized table">
                                                    <TableHead>
                                                        <TableRow>
                                                            <StyledTableCell >Order Number</StyledTableCell>
                                                            <StyledTableCell>Product</StyledTableCell>
                                                            <StyledTableCell>SKU</StyledTableCell>
                                                            <StyledTableCell>Status</StyledTableCell>
                                                            <StyledTableCell>Purchase Date</StyledTableCell>
                                                            <StyledTableCell>Bids</StyledTableCell>
                                                            <StyledTableCell >Winning Bid / Buy Now Price</StyledTableCell>
                                                            <StyledTableCell>Manage</StyledTableCell>
                                                        </TableRow>
                                                    </TableHead>
                                                    <TableBody>
                                                        {auctionReport?.map((row, i) => {
                                                            let xref = row.user_order_auction_item_xrefs[row.user_order_auction_item_xrefs.length-1]
                                                            return <StyledTableRow key={row.id}>
                                                                <StyledTableCell>{xref ? `BG_${xref?.user_order.id}` : '--'}</StyledTableCell>
                                                                <StyledTableCell>{row.name}</StyledTableCell>
                                                                <StyledTableCell>{row.sku}</StyledTableCell>
                                                                {/* <StyledTableCell>{xref?.user_order.payment_status === 'success' ? orderStatus(xref?.user_order.status) : (xref?.user_order.payment_status ? `Payment ${xref?.user_order.payment_status}` : row.is_ended ? 'Ended' : 'Running ')}</StyledTableCell> */}
                                                                {/* <StyledTableCell>{xref?.user_order.payment_status === 'success' ? orderStatus(xref?.user_order.status) : (xref?.user_order.payment_status ? `Payment ${xref?.user_order.payment_status}` :( moment(row.end_date) < moment() ) ? 'Ended' : 'Running ')}</StyledTableCell> */}
                                                                <StyledTableCell>{xref?.user_order.payment_status === 'success' ? orderStatus(xref?.user_order.status) : (xref?.user_order.payment_status ? `Payment ${xref?.user_order.payment_status}` :(row?.status === 'sold') ? 'Ended' : 'Running ')}</StyledTableCell>
                                                                <StyledTableCell>{xref ? moment(xref?.user_order?.created).format('MM/DD/YYYY') : '--'}</StyledTableCell>

                                                                <StyledTableCell><Tooltip title={'Click to view Bid history'} onClick={() => { handleBidsTable([...row.bids].reverse()) }}><span className={style.oar_bid_count} >{row.bids.length}{row.bids.length ? <RemoveRedEyeOutlinedIcon/>:null }</span></Tooltip></StyledTableCell>

                                                                <StyledTableCell>{row.bids.length ? xref ? xref?.user_order.subtotal ? USDollar.format(xref?.user_order.subtotal) : 'Not available' : USDollar.format(row.bids[row.bids.length - 1]?.bid_amount) : '--'}</StyledTableCell>
                                                                <StyledTableCell>

                                                                    {xref && xref.user_order.payment_status === 'success' && xref?.user_order?.status !== '8' &&
                                                                        // <div onClick={() => navigate(`/admin/edit-order/${xref?.id}`, { state: { bid: row.bids.length, winningBid: row.bids[row.bids.length - 1]?.bid_amount,bidders:[...row?.bids].reverse() } })}
                                                                        //     className='table-btn'> <BorderColorIcon sx={{ fontSize: "16px", color: "#5EABD6" }} /> <span style={{ color: "#5EABD6", fontWeight: "600" }}>Edit</span></div>}

                                                                        <TableButton
                                                                            onClick={() => navigate(`/admin/edit-order/${xref?.id}`, { state: { bid: row.bids.length, winningBid: row.bids[row.bids.length - 1]?.bid_amount,bidders:[...row?.bids].reverse() } })}
                                                                            Icon={BorderColorIcon}
                                                                        />
                                                                     }
                                                                    {xref && xref.user_order.payment_status === 'failed' &&
                                                                    // <div onClick={() => navigate(`/admin/edit-order/${xref?.id}`, { state: { bid: row.bids.length, winningBid: row.bids[row.bids.length - 1]?.bid_amount,bidders:row?.bids } })}
                                                                    //  className='table-btn'> <BorderColorIcon sx={{ fontSize: "16px", color: "#5EABD6" }} /> <span style={{ color: "#5EABD6", fontWeight: "600" }}>Edit</span></div>}
                                                                    <TableButton
                                                                            onClick={() => navigate(`/admin/edit-order/${xref?.id}`, { state: { bid: row.bids.length, winningBid: row.bids[row.bids.length - 1]?.bid_amount,bidders:row?.bids } })}
                                                                            Icon={BorderColorIcon}
                                                                        />
                                                                     }

                                                                    {
                                                                        (godAccess && (row.bids.length && row?.status === 'active' && moment(row?.end_date) > moment())) ?
                                                                        <TableButton
                                                                            onClick={() => showEndAuctionAlert(row?.id)}
                                                                            // Icon={BorderColorIcon}
                                                                            text='End Auction'
                                                                        />
                                                                        :null
                                                                     }
                                                                            
                                                                </StyledTableCell>

                                                            </StyledTableRow>
                                                        })}
                                                    </TableBody>
                                                </Table>
                                            </TableContainer>
                                            <TablePagination
                                                rowsPerPageOptions={[10, 20, 25, 50, 100]}
                                                component="div"
                                                count={totalAuctions}
                                                rowsPerPage={auctionLimit}
                                                page={auctionPage - 1}
                                                onPageChange={(e, newPage) => dispatch(setAuctionPage({ page: newPage + 1 }))}
                                                onRowsPerPageChange={(e) => dispatch(setAuctionPage({ page: 1, limit: +e.target.value }))}
                                            />
                                        </Paper> : <Paper sx={{ height: '300px', textAlign: 'center', paddingY: '140px' }}>
                                            {selectedEvent.value ? (soldStatusFilter.showRunning || soldStatusFilter.showUnsold || soldStatusFilter.showRunning) ?
                                                <p>No auction to show</p> : <p>Please choose atleast one checkbox</p>
                                                : <p>Please choose {getLabel()}</p>}
                                        </Paper>
                                    }
                                </div>}


                                {/* ///// donor report ////// */}

                                {selectedReport.value === 'donor' && <div>
                                    {
                                        donorReportLoading ? <LoadingModal style={{ position: 'relative', width: '100%', marginLeft: '0px', minHeight: '300px', backgroundColor: 'white' }} isOpen={true} /> : donorData?.length ? <Paper>
                                            <TableContainer>
                                                <Table sx={{ minWidth: 700 }} aria-label="customized table">
                                                    <TableHead>
                                                        <TableRow>
                                                            <StyledTableCell >Email</StyledTableCell>
                                                            <StyledTableCell>First Name</StyledTableCell>
                                                            <StyledTableCell>Last Name</StyledTableCell>
                                                            <StyledTableCell>Number of Orders</StyledTableCell>
                                                            <StyledTableCell>Order Total</StyledTableCell>

                                                        </TableRow>
                                                    </TableHead>
                                                    <TableBody>
                                                        {donorData?.map((row, i) => {
                                                            return <StyledTableRow key={row.id}>
                                                                <StyledTableCell >{row?.user?.email}</StyledTableCell>
                                                                <StyledTableCell>{row?.user?.first_name}</StyledTableCell>
                                                                <StyledTableCell>{row?.user?.last_name}</StyledTableCell>
                                                                <StyledTableCell> <Tooltip title={'Click to view Order details'}><span onClick={() => {handleDonorOrders(row?.user_id)}} style={{cursor:'pointer'}}>{row?.total_orders} {row?.total_orders ? <RemoveRedEyeOutlinedIcon style={{marginLeft:'8px'}}/>:null }</span></Tooltip></StyledTableCell>
                                                                <StyledTableCell>{row?.total_order_amount}</StyledTableCell>
                                                            </StyledTableRow>
                                                        })}
                                                    </TableBody>
                                                </Table>
                                            </TableContainer>
                                            <TablePagination
                                                rowsPerPageOptions={[10, 20, 25, 50, 100]}
                                                component="div"
                                                count={totalDonors}
                                                rowsPerPage={donorLimit}
                                                page={donorPage - 1}
                                                onPageChange={(e, newPage) => setDonorPage(newPage + 1)}
                                                onRowsPerPageChange={(e) => { setDonorLimit(+e.target.value); setDonorPage(1) }}
                                            />
                                        </Paper> : <Paper sx={{ height: '300px', textAlign: 'center', paddingY: '140px' }}>
                                            {selectedEvent.value ?
                                                <p>No donors to show</p>
                                                : <p>Please choose {getLabel()}</p>}
                                        </Paper>
                                    }
                                </div>}

                                {/* Item sales report table */}
                                {selectedReport.value === 'item-sales' && 
                                 <div>
                                    <ItemSalesReportTable pageType={marketplace ? "marketplace" : "store"} />
                                 </div>
                                }

                                {/* category sales report */}
                                {
                                    selectedReport.value === 'category-sales' &&
                                    <CategorySalesReportTable pageType={marketplace ? "marketplace" : "store"} />
                                }

                            </Paper>
                            <Modal title={tableType} isOpen={modal} onClose={() => setModal(false)} onDone={() => setModal(false)} buttonText='Close' modalStyle={{ marginTop: '30px' }} contentStyle={{ width: '90%', overflowY: 'auto' }} >
                               <TableContainer>
                               { modalLoading ? <LoadingModal style={{ position: 'relative', width: '100%', marginLeft: '0px', minHeight: '300px', backgroundColor: 'white' }} isOpen={true} /> 
                                    : <>
                                    {tableBody?.length ? <Table sx={{ minWidth: 700 }} aria-label="customized table">
                                        <TableHead>

                                             <TableRow>
                                                {
                                                    tableHeading?.map((item) => {
                                                        return (
                                                            <StyledTableCell  key={item} >{item}</StyledTableCell>
                                                        )
                                                    })
                                                }
                                             </TableRow>

                                        </TableHead>
                                        <TableBody>
                                            {/* table row for bid details */}
                                            { tableType === 'Bids' && tableBody?.map((row, i) => {
                                                return <StyledTableRow key={row.id}>
                                                    <StyledTableCell>{row.user.first_name}</StyledTableCell>
                                                    <StyledTableCell>{row.user?.last_name}</StyledTableCell>
                                                    <StyledTableCell>{row.user.email}</StyledTableCell>
                                                    <StyledTableCell>{moment(row.bid_date).format('MM/DD/YYYY hh:mm')}</StyledTableCell>
                                                    <StyledTableCell>{USDollar.format(row.bid_amount)}</StyledTableCell>
                                                </StyledTableRow>
                                            })}

                                            {/* table row for donor details */}
                                            { tableType === 'Order Details' && tableBody?.map((row, i) => {
                                                return <StyledTableRow key={i}>
                                                    <StyledTableCell>{row?.auction_item?.name}</StyledTableCell>
                                                    <StyledTableCell>
                                                        <div className={style.sku_col}>
                                                            <span>{row.product_sku_id}</span>
                                                            {
                                                                row?.variant_combination &&
                                                                <VariantCombination combiantionKeys={Object.keys(row?.variant_combination?.fullObject)} combiantionValues={Object.values(row?.variant_combination?.fullObject)} />
                                                            }
                                                        </div>
                                                    </StyledTableCell>
                                                    <StyledTableCell>{moment(row?.user_order?.created).format('MM/DD/YYYY hh:mm')}</StyledTableCell>
                                                    <StyledTableCell>{USDollar.format(row?.user_order?.subtotal)}</StyledTableCell>
                                                </StyledTableRow>
                                            })}

                                        </TableBody>
                                    </Table> : <Paper sx={{ height: '100px', textAlign: 'center', paddingY: '140px' }}>
                                        <p>No Bids to show</p></Paper>}
                               { tableType === 'Order Details' && <TablePagination
                                     rowsPerPageOptions={[10, 20, 25, 50, 100]}
                                     component="div"
                                     count={totalData}
                                     rowsPerPage={paginationLimit}
                                     page={paginationPage - 1}
                                     onPageChange={(e, newPage) => dispatch(setPaginationPage({paginationPage:newPage + 1}))}
                                     onRowsPerPageChange={(e) => {dispatch(setPaginationLimit({paginationLimit: +e.target.value})); dispatch(setPaginationPage({paginationPage: 1})) }}
                                />}
                                </>}
                                </TableContainer>
                            </Modal>
                            <Alert isOpen={!!auctionError || orderError} message='Something went wrong' onClose={clearErrors} onConfirm={clearErrors} />
                            <Alert isOpen={successAlert.isOpen} message={successAlert.message} onClose={()=>closeAlert(false)} type={successAlert.alertType} onConfirm={()=>successAlert.confirmFunction(successAlert.confirmMetaData)} />
                        </>
                    }
                </>}
        </Container>
    )
}

export default OrderAndReportsMarketStore