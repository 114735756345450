import { TableCell, TableRow, styled, tableCellClasses } from "@mui/material";
import React, { useEffect, useLayoutEffect, useState } from "react";
import { DateInp, Input, Select } from "./Form";
import TableButton from "../../../components/TableButton/TableButton";
import constants, { COLORS_OBJECT } from "../../../../constants/en";
import Alert from "../../../components/Alert/Alert";
import moment from "moment";

const redColor = COLORS_OBJECT.color[0]

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      fontWeight: 600,
      borderBottom: "1px solid black"
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
  }));

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    // '&:nth-of-type(odd)': {
    //   backgroundColor: "lightblue",
    // },
    // '&:last-child td, &:last-child th': {
    //   border: 0,
    // },
  }));
   
  
  const AsnTableRow = ({index=null, removeItem=()=>null, item={}, data={}, addItems=()=>null, items=[], createUpdateItem=()=>null, setNoDonorIdItems,noDonorIdItems, sendDataChange=()=>null, handleChangeAfterAllSelected=()=>null, addAllClicked=false, setItems=()=>null, removeItemsSkuArray=[], selectedEvent}) => {
    // console.log(item, "item is here")
    const [asnData, setAsnData] = useState({
        auction_item_id: item?.id,
        warehouse_id: item?.saved_warehouse_id ? item?.saved_warehouse_id : data?.warehouseReq?.find((item, idx) => item?.display_name === 'Las Vegas')?.id, /// for sent items we have warehouse_id already saved in asn table so we use that
        donor_id: item?.donorData?.id ? item?.donorData?.id : null ,
        expected_delivery: item?.saved_expected_delivery ? new Date(item?.saved_expected_delivery).toISOString() : new Date(new Date().getTime()+7*24*60*60*1000).toISOString(), // adding 7 days to the current date as default  /// for sent items we have expected delivery already saved in asn table so we use that
        expected_quantity: item?.received_asn_quantity ? item?.received_asn_quantity : item?.actual_qty, /// received_asn_quantity is the quantity which is created in asn table while making items sent and actual_quantity is the quantity in items table.
        sku:item?.sku,
        created: moment(item?.created).format("YYYY-MM-DDTHH:mm"),
        store_item_id: item?.store_item_id
    })
    const [alert, setAlert] = useState(null);
    const [addedIcon, setAddedIcon] = useState(false)
    const [selectedBgColor, setSelectedBgColor] = useState('')
    const [confirmUpdateAlert, setConfirmUpdateAlert] = useState({value:false, message:''})
    const handleFormChange = (event) => {
        const {name, value} = event.target;
        setAsnData({...asnData, [name]:value})
        if(item?.saved_asn_status === 'sent' && name === 'expected_quantity') {
            sendDataChange(name, value, asnData?.sku)
        }
        if(addedIcon) {
            handleChangeAfterAllSelected(name, value, asnData?.sku)
        }
        // if(checked && name === 'auction_item_id') {
        //     setAsnData({...data, auction_item_id:value})
        // }
        // else if(!checked && name === 'auction_item_id') {
        //     setAsnData({
        //         auction_item_id: '',
        //         warehouse_id:'',
        //         donor_id: '',
        //         expected_delivery: new Date().toISOString(),
        //         expected_quantity: ''
        //     })
        //     removeItem(asnData)
        //     setAddedIcon(false)
        //     setSelectedBgColor('')
        // }
    }

    useEffect(() => {
        if(item?.saved_asn_status === 'sent') {
        createUpdateItem(asnData, true)/// true for checking if the function is triggerd from sent
        }
    },[])

    useEffect(()=> {
        if(!item?.donorData?.id) {
            setNoDonorIdItems(noDonorIdItems + 1)
        }
    },[])

    const addAsnData = async() => {
        if(!asnData.auction_item_id || !asnData.warehouse_id || !asnData.donor_id || !asnData.expected_delivery ) {
            setAlert(constants.ADMIN_COPY_ASN_TABLE_ROW_VALIDATION)
            return;
        }
        setAddedIcon(true)
       const itemAdded =  await addItems(asnData)
       if(itemAdded) setSelectedBgColor('#66339938')
    }

    const handleRemoveItem = () => {
        removeItem(asnData)
        setAddedIcon(false)
        setSelectedBgColor('')
        setAsnData({
            ...asnData,
            warehouse_id:data?.warehouseReq?.find((item, idx) => item?.display_name === 'Las Vegas')?.id,
            // donor_id: '',
            expected_delivery: new Date().toISOString(),
            expected_quantity: item?.actual_qty
        })
    }

    useLayoutEffect(() => {
        if(addAllClicked) {
            setSelectedBgColor('#66339938')
            setAddedIcon(true)
            const itemPresent =  items?.find((product) => product?.sku === item?.sku )
        if(!itemPresent && !removeItemsSkuArray?.includes(item?.sku) && item?.donorData?.id && item?.donorData?.id != null) {
           const allClickedAsnData = {
                auction_item_id: item?.id,
                warehouse_id: item?.saved_warehouse_id ? item?.saved_warehouse_id : data?.warehouseReq?.find((item, idx) => item?.display_name === 'Las Vegas')?.id, /// for sent items we have warehouse_id already saved in asn table so we use that
                donor_id: item?.donorData?.id ? item?.donorData?.id : null ,
                expected_delivery: item?.saved_expected_delivery ? new Date(item?.saved_expected_delivery).toISOString() : new Date(new Date().getTime()+7*24*60*60*1000).toISOString(), // adding 7 days to the current date as default  /// for sent items we have expected delivery already saved in asn table so we use that
                expected_quantity: item?.received_asn_quantity ? item?.received_asn_quantity : item?.actual_qty, /// received_asn_quantity is the quantity which is created in asn table while making items sent and actual_quantity is the quantity in items table.
                sku:item?.sku,
                created: moment(item?.created).format("YYYY-MM-DDTHH:mm"),
                store_item_id: item?.store_item_id
            }
            setItems(prevItems => [...prevItems, allClickedAsnData]);
        }
       }
    },[])

    useEffect(() => {
       const itemPresent =  items?.find((product) => product?.sku === item?.sku )
       if(itemPresent) {
        setSelectedBgColor('#66339938')
        setAddedIcon(true)
        setAsnData({
            ...asnData,
            warehouse_id:itemPresent?.warehouse_id,
            donor_id: itemPresent?.donor_id,
            expected_delivery: itemPresent?.expected_delivery,
            expected_quantity: itemPresent?.expected_quantity,
            sku:itemPresent?.sku,

        })
       }
       else {
        setSelectedBgColor('')
        setAddedIcon(false)
       }
    },[items])

    const openConfirmUpdateAsnAlert = () => {
        setConfirmUpdateAlert({value:true, message:constants.ADIMIN_COPY_UPDATE_ASN_AND_QUANTITY_ALERT})
    }
    
    const handleSentItemClick = async() => {
        await createUpdateItem(asnData)
        setConfirmUpdateAlert({value:false, message:''})
    }

    return (
        <>
            <StyledTableRow sx={{backgroundColor:(item?.saved_asn_status !== 'sent') && selectedBgColor}}>
                {/* <StyledTableCell align="center">
                    <input onChange={handleFormChange} name='auction_item_id' type="checkbox" value={item?.id} />
                </StyledTableCell> */}
                <StyledTableCell>{item?.sku}, {item?.name} <br/> 
                {item?.combination ? <span style={{color:redColor}} className="variant-text">{ item?.combination}</span>: null}
                </StyledTableCell>
                <StyledTableCell>
                        {/* <Select
                            name={`donor_id`}
                            placeholder="Select one"
                            value={asnData?.donor_id}
                            disabled={asnData.auction_item_id === ''}
                            Options={() => data?.donorsReq && data?.donorsReq?.map((i, idx) =>
                                <option value={i.id} key={idx}>
                                    {i.contact_firstname} {i.contact_lastname} - {i.org_name},
                                </option>
                            )}
                            onchange={handleFormChange}
                        /> */}
                    {item?.donorData?.id ? item?.donorData?.org_name : 'Donor Unknown'}
                </StyledTableCell>
                {
                    (!selectedEvent?.value || selectedEvent?.eventType === 'Marketplace') &&
                    <StyledTableCell>{item?.auction?.market_place == '1' ? item?.auction?.title : null}</StyledTableCell>
                }
                <StyledTableCell>{moment(item?.created).format("YYYY-MM-DD")} </StyledTableCell>
                <StyledTableCell>
                    <Select
                        name={`warehouse_id`}
                        placeholder="Select one"
                        value={asnData?.warehouse_id}
                        disabled={(asnData.auction_item_id === '' || !asnData?.donor_id) || (item?.saved_warehouse_id)}
                        Options={() => data?.warehouseReq && data?.warehouseReq?.map((i, idx) =>
                            <option value={i.id} key={idx}>
                                {i?.display_name}
                            </option>
                        )}
                        onchange={handleFormChange}
                    />
                </StyledTableCell>
                <StyledTableCell>
                    <DateInp
                        name='expected_delivery'
                        min={new Date().toISOString().split('T')[0]}
                        value={new Date(asnData?.expected_delivery).toISOString().split('T')[0]}
                        onChange={handleFormChange}
                        disabled={asnData.auction_item_id === '' || !asnData?.donor_id || item?.saved_expected_delivery}
                    />
                </StyledTableCell>
                <StyledTableCell>
                    <Input
                        name='expected_quantity'
                        placeholder='Qty here...'
                        type='number'
                        value={asnData?.expected_quantity}
                        onchange={handleFormChange}
                        onWheel={(e) => e.target.blur()}
                        // disabled={asnData.auction_item_id === '' || !asnData?.donor_id}
                        // disabled={item?.saved_asn_status !== 'sent' || !item?.store_item_id} /// if the item is filtered by status it have a status field which is the status of the asn data
                        disabled={!asnData?.donor_id || !item?.store_item_id}
                    />
                </StyledTableCell>
                <StyledTableCell>
                    {item?.actual_qty}
                </StyledTableCell>
                <StyledTableCell>
                    {/* {
                     addedIcon
                     ? <TableButton disabled={asnData.auction_item_id === '' || !asnData?.donor_id} onClick={handleRemoveItem} color={redColor} text='Remove' />
                     :<TableButton disabled={asnData.auction_item_id === '' || !asnData?.donor_id || (item?.saved_asn_status === 'sent' && !item?.store_item_id)} onClick={addAsnData} color={redColor} text='Add' />
                    } */}

                    {
                        item?.saved_asn_status === 'sent' ?
                        <TableButton onClick={openConfirmUpdateAsnAlert} color={redColor} text='Update' />
                        :
                        <>
                        {addedIcon
                            ? 
                            <TableButton disabled={asnData.auction_item_id === '' || !asnData?.donor_id} onClick={handleRemoveItem} color={redColor} text='Remove' />
                            :
                            <TableButton disabled={asnData.auction_item_id === '' || !asnData?.donor_id } onClick={addAsnData} color={redColor} text='Add' />
                        }
                        </>
                    }
                </StyledTableCell>
            </StyledTableRow>
            <Alert isOpen={! !alert} message={alert}
                onClose={() => setAlert(null)} />
            <Alert 
               type='confirm'
               isOpen={confirmUpdateAlert.value} 
               message={confirmUpdateAlert.message} 
               onClose={() => setConfirmUpdateAlert({value:false, message:''}) }
               onConfirm={() => handleSentItemClick()}
            />
        </>
    )
}

export default AsnTableRow;