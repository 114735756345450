import { useEffect, useState } from "react";
import authService from "../../../services/auth-services";
import AccessDenied from "../../components/AccessDenied/AccessDenied";
import Container from "../../components/HOC/Container/Container";
import LoadingModal from '../../components/LoadingModal/LoadingModal';
import CalendarMonthOutlinedIcon from '@mui/icons-material/CalendarMonthOutlined';
import "./asn.css";
// import {Input,Select,DateInp} from "./components/Form"
import { useNavigate } from "react-router-dom";
import auctionServices, {getWarehouse,getProducts,submitAsn} from '../../../services/auction-services';
import logger from "../../../utils/logger";
import {  Paper, TableBody, TableCell, tableCellClasses, TableContainer, TableHead, TablePagination, TableRow, Tooltip, Typography } from '@mui/material';
import styled from '@emotion/styled';
import Table from '@mui/material/Table';
// import InputComponent from '../../components/Input/Input';
import DropDown from "../../components/DropDown/DropDown";
import AsnTableRow from "./components/AsnTableRow";
import constants, { COLORS_OBJECT } from "../../../constants/en";
import Alert from "../../components/Alert/Alert";
import Multiselect from "multiselect-react-dropdown";
import CheckBoxComponent from "../../components/CheckBoxComponent/CheckBoxComponent";
import TableButton from "../../components/TableButton/TableButton";
import moment from "moment";
import UploadFile from "../../components/UploadFile/UploadFile";
import { getEventType } from "../../../utils/util-functions";
const redColor = COLORS_OBJECT.color[0]
const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      fontWeight: 600,
      borderBottom: "1px solid black"
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
  }));

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    // '&:nth-of-type(odd)': {
    //   backgroundColor: "lightblue",
    // },
    // '&:last-child td, &:last-child th': {
    //   border: 0,
    // },
  }));

const Asn = () =>{
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false)
    const [permission, setPermission] = useState(false);
    const [data,setData] = useState({loading: true,loadingAsn: false,expected_delivery:new Date().toISOString()});
    const [items,setItems] = useState([]);
    const [limit, setLimit] = useState(20);
    const [page, setPage] = useState(1);
    const [totalProducts, setTotalProducts] = useState(null)
    const [alert, setAlert] = useState(null)
    const [selectedEvent, setSelectedEvent] = useState(null)
    const [showAsnSent, setShowAsnSent] = useState('')
    const [addAllClicked, setAddAllClicked] = useState(false)
    const [noDonorIdItems, setNoDonorIdItems] = useState(0)
    const [updateAllAsnSentItems, setUpdateAllAsnSentItems] = useState([])
    const [updateAllAlert, setUpdateAllAlert] = useState({value:false, message:constants.ADMIN_COPY_UPDATE_ALL_ALERT})
    const [fileUploadStatus,setFileUplaodStatus]=useState('ready_to_upload');
    const [removeItemsSkuArray, setRemoveItemsSkuArray] = useState([])
    // const handleCheckBox = (event) => {
    //     if(event?.target?.checked) {
    //         addItems()
    //     }
    // }

    const setLimitAndPage = (newPage=1, newLimit=20) => {
        setPage(newPage);
        setLimit(newLimit);
    }

    const addItems = (asnData={}, fromAddAll=false) => {
        // console.log(asnData?.auction_id , asnData?.auction_item_id , asnData?.warehouse_id , asnData?.donor_id , asnData?.expected_delivery , asnData?.expected_quantity, asnData?.sku, "bla bla bal")
        if( asnData?.auction_item_id && asnData?.warehouse_id && asnData?.donor_id && asnData?.expected_delivery  && asnData?.sku) { // in this if condition asnData?.expected_quantity was removed just to allow 0 as quantity
            let i = [...items];
            if(fromAddAll) {
                i = [] 
            }
            const foundAucItemId = i.find(f=>f.auction_item_id === asnData?.auction_item_id);
            const foundWareId = i.find(f=>f.warehouse_id === asnData?.warehouse_id);
            const foundDonId = i.find(f=>f.donor_id === asnData?.donor_id);
            const foundSku = i.find(f=> f.sku === asnData?.sku);
            if(foundAucItemId && foundWareId && foundDonId && foundSku) {
                logger('./pages/components/Donation/Donation.jsx','Item already present');  
            } else {
                const product_name = data?.productsRes?.find(f=>f.sku === asnData?.sku);
                setRemoveItemsSkuArray(removeItemsSkuArray.filter(obj => obj !== asnData?.sku))
                i.push({
                    auction_id:asnData?.auction_id,
                    auction_item_id:asnData?.auction_item_id,
                    warehouse_id:asnData?.warehouse_id,
                    donor_id:asnData?.donor_id,
                    expected_delivery:asnData?.expected_delivery,
                    expected_quantity:asnData?.expected_quantity,
                    sku: product_name?.sku,

                    product_name:`${product_name?.sku}, ${product_name?.name}`,
                    warehouse_name:data?.warehouseReq?.find(f=>f.id === Number(asnData?.warehouse_id))?.display_name,
                    donor_name:`${data?.donorsReq?.find(f=>f.id === Number(asnData?.donor_id))?.contact_firstname}`+" "+`${data?.donorsReq?.find(f=>f.id === Number(asnData?.donor_id))?.contact_lastname}`,
                    store_item_id: asnData?.store_item_id
                });
                if(fromAddAll) {
                    return i;
                }
                setItems(i);
                setData({
                    warehouseReq:data?.warehouseReq,
                    donorsReq:data?.donorsReq,
                    eventsReq:data?.eventsReq,
                    productsRes:data?.productsRes,
                    error:'',
                    ErrorColor:'',
                    loading:false,
                    loadingAsn:false,
                    expected_delivery: new Date().toISOString()
                });
                let itemAdded=true;
                return itemAdded
            }
        }
    }

    const removeItem = (asnData) => {
        // setItems(items.filter((item, idx) => item?.auction_item_id !== asnData?.auction_item_id))
        setItems(items.filter((item, idx) => item?.sku !== asnData?.sku))
        setRemoveItemsSkuArray(prev => [...prev, asnData?.sku])
    }

    const variantCombination = ([head, ...[tailHead, ...tailTail]]) => {
        if (!tailHead) return head;
      
        const combined = tailHead.reduce((acc, x) => {
          return acc.concat(head.map((h) => `${h}/${x}`));
        }, []);
        return variantCombination([combined, ...tailTail]);
      };

    const handleAuctionSelected = async(name='',value, allProducts={}) => {
        try {
            setLoading(true)
            let products;
            if(allProducts?.result?.length || name === '') {
                products = allProducts;
            }else {
                if(showAsnSent !== '') {
                    //// for showing items if we filter by showSend
                    products = await auctionServices.getAsnItemsByStatus(page, limit, showAsnSent, value)/// showAsnSend is the fitler status , value is the auction_id
                }
                else {
                    products = await auctionServices.getProductsForAsnByEvent(value, page, limit);
                }
            }
            if(products?.result?.length) {
                setTotalProducts(products?.count)
                const devs = products?.result?.map(i=>{
                    if(i?.variants?.length) {
                        let combination = [];
                        let combinationKeys = [];
                        if(Object.keys(i?.options).length > 0) {
                             combination = variantCombination(Object.values(i?.options));
                             combinationKeys = Object.keys(i?.options).join('/');
                        }
                        const varts = i?.variants?.map((v, index)=>({
                            id:i?.id,
                            name:i?.name,
                            sku:v?.sku,
                            donorData: i?.donor,
                            created:i?.created,
                            combination: combination.length > 0 ? `${combinationKeys}: ${combination[index]}` : null,
                            actual_qty: v?.quantity_in_stock,
                            saved_warehouse_id: i?.warehouse_id, //// these details come from asn table if we filter according to status
                            saved_expected_delivery: i?.expected_delivery, //// these details come from asn table if we filter according to status
                            saved_asn_status: i?.status, //// these details come from asn table if we filter according to status
                            store_item_id: i?.store_item?.id,
                            received_asn_quantity:i?.expected_quantity, /// quantity which is created in asn table while making items sent.
                            auction: i?.auction
                        }));
                        return {
                                 id:i.id,
                                 name:i.name,
                                 sku:i.sku,varts:varts, 
                                 donorData: i?.donor,
                                 created:i?.created,
                                 actual_qty: i?.quantity_in_stock, 
                                 saved_warehouse_id: i?.warehouse_id, //// these details come from asn table if we filter according to status
                                 saved_expected_delivery: i?.expected_delivery, //// these details come from asn table if we filter according to status
                                 saved_asn_status: i?.status, //// these details come from asn table if we filter according to status
                                 store_item_id: i?.store_item?.id,
                                 received_asn_quantity:i?.expected_quantity, /// quantity which is created in asn table while making items sent.
                                 auction: i?.auction
                            };
                    } else if(i?.store_item) {
                        return {
                            id:i?.id,
                            name:i?.name,
                            sku:i?.store_item?.sku,
                            donorData: i?.donor,
                            created:i?.created,
                            actual_qty: i?.store_item?.quantity_in_stock,
                            saved_warehouse_id: i?.warehouse_id, //// these details come from asn table if we filter according to status
                            saved_expected_delivery: i?.expected_delivery, //// these details come from asn table if we filter according to status
                            saved_asn_status: i?.status, //// these details come from asn table if we filter according to status
                            store_item_id: i?.store_item?.id,
                            received_asn_quantity:i?.expected_quantity, /// quantity which is created in asn table while making items sent.
                            auction: i?.auction
                        };
                    }
                    return {
                             id:i?.id,
                             name:i?.name,
                             sku:i?.sku, 
                             donorData: i?.donor, 
                             created:i?.created, 
                             actual_qty:1,
                             saved_warehouse_id: i?.warehouse_id, //// these details come from asn table if we filter according to status
                             saved_expected_delivery: i?.expected_delivery,//// these details come from asn table if we filter according to status
                             saved_asn_status: i?.status, //// these details come from asn table if we filter according to status
                             store_item_id: i?.store_item?.id,
                             received_asn_quantity:i?.expected_quantity, /// quantity which is created in asn table while making items sent.
                             auction: i?.auction
                        };
                });
                const varts = devs?.filter(f=>f.varts)?.map(m=>m.varts).flat(Infinity);
                const prods = [...devs,...varts].filter(f=>!Boolean(f.varts));
                if(allProducts?.result?.length) {
                    setLoading(false)
                    return prods
                }
                else{
                    const dons = await auctionServices.getdonorsListbyAuctionId(value)
                    setData({...data,
                        [name]:value,
                        productsRes:prods,
                        donorsReq:dons.donors,   
                    });
                    setLoading(false)
                    return prods;
                }
                // products?.result?.map(i=>({id:i.id,name:i.name,sku:i.sku}))
            } else {
                setData({...data, productsRes:[], donorsReq:[]});
                // setTimeout(()=>{
                //     setData({...data,error:''});
                // },5000);
                setLoading(false)
            }
        } catch(err) {
            logger('./pages/components/Donation/Donation.jsx','handleChange(): catch',err);  
            setLoading(false)
            // setData({...data,error:err.message});
            setData({...data, productsRes:[], donorsReq:[]});
            // setTimeout(()=>{
            //     setData({...data,error:''});
            // },5000);
        }
    }

    const handleChange = async (e, fromAuction=false) => {
        let value;
        let name;
        if(addAllClicked) {
           setAddAllClicked(false)
           setItems([])
        }
        if(fromAuction) {
            value = e?.value;
            name = 'auction_id';
        }else {
            value = e.target.value;
            name = e.target.name;
        }
        if(name === 'auction_id') {
            setLimitAndPage()
            await handleAuctionSelected(name, value)
        } 
        // else if(name === 'expected_delivery') {
        //     setData({...data,[name]:new Date(value).toISOString()});
        // } else if(name === 'expected_quantity') {
        //     if(Number(value) >= 0) {
        //         setData({...data,[name]:value});
        //     }
        // } else if(name === 'auction_item_id'){
        //     if(e?.target?.checked) {
        //         setData({...data, [name]: value})
        //     }
        // }
        //  else {
        //     setData({...data,[name]:value});
        // }
    }
    const getPageData = async () => {
        try {
            // if(data?.loading) {
                setLoading(true)
                const w_data = await getWarehouse();
                const dons = await auctionServices.getdonorsList();
                const events = await auctionServices.getAllEventsForAdmin();
                let products;
                if(selectedEvent) {
                    products = await handleAuctionSelected("auction_id", selectedEvent.value )
                    setData({
                        ...data,
                        warehouseReq:w_data?.data,
                        eventsReq:events?.data?.map(i=>({value:i.id,label:`${i.title} ( ${getEventType(i)} )`, status:i.status, eventType: getEventType(i)})),
                        productsRes:products,
                        loading:false
                    });
                }else {
                    let allStatusItems;
                    if(showAsnSent !== '') {
                        //// for showing items if we filter by showSend
                        allStatusItems = await auctionServices.getAsnItemsByStatus(page, limit, showAsnSent)
                    }
                    else {
                        allStatusItems = await auctionServices.getProductsForAsnNotByEvent(page, limit, "known")
                    }
                    setTotalProducts(allStatusItems?.count)
                    products = await handleAuctionSelected("", "", allStatusItems )
                    setData({
                        ...data,
                        warehouseReq:w_data?.data,
                        donorsReq:dons.data,
                        // eventsReq:events?.data?.map(i=>({id:i.id,title:i.title, status:i.status})),
                        eventsReq:events?.data?.map(i=>({value:i.id,label:`${i.title} ( ${getEventType(i)} )`, status:i.status, eventType: getEventType(i)})),
                        productsRes:products,
                        loading:false
                    });
                }
                //    const events = await auctionServices.getAllEventsOfUser(ctx?.userId,1,20);
                // if(addAllClicked) {
                //     setAddAllClicked(false)
                //     setItems([])
                // }
                setUpdateAllAsnSentItems([])
                setLoading(false)
            // }
        } catch(err) {
            setLoading(false)
            navigate('/admin');
        }
    }
    useEffect(()=>{
        getPageData();
    },[ page, limit, selectedEvent, showAsnSent]);

    const clearFilter = () => {
        setLimitAndPage()
        setSelectedEvent(null)
        // getPageData()
    }

    const verifyPermission = async () => {
        setLoading(true)
        try {
          const permission = await authService.checkLimitedPermissions(14)
          if(permission.access) {
            setPermission(true)
          }
          setLoading(false)
        } catch (error) {
          if(error?.response?.status === 403 || !error?.response?.data?.access) {
            setPermission(false)
          }
          setLoading(false)
        }
      }
    
      useEffect(() => {
        verifyPermission()
      }, [])

      const handleShowSent = (event) => {
        setItems([])
        if(event.target?.value === 'sent') {
            setShowAsnSent("")
        }
        else {
            setShowAsnSent('sent')
        }
      }

      const updateAsnItemsQuantities = async(itemsArray, updateAll=false) => {
        try {
            setUpdateAllAlert({...updateAllAlert, value:false})
            setLoading(true)
            const response = await auctionServices.updateAsnItemsQuantity({records:itemsArray}, updateAll)
            setLoading(false)
            setData({
                ...data,
                loadingAsn:false,
                error:'Success adding asn',//Here it is success
                ErrorColor:'green',
                loading:false,
                expected_delivery: new Date().toISOString()
            });
            let message = response?.msg;
            if(!response?.msg){
                message = constants.constants.ADMIN_COPY_ASN_UPDATED_ALERT
            }
             setAlert(message)
            setItems([]);
            setUpdateAllAsnSentItems([])
            if(page === 1) {
                getPageData();
            }
            else {
                setPage(1)
            }
        } catch (error) {
            setLoading(false)
            setData({...data,
                loadingAsn:false,
                error:error.message
            });
            setItems([]);
            setAlert(constants.ADMIN_COPY_ASN_CREATION_ERROR)
        }
      }

      const createUpdateItem = (asnData, forAllSentData=false) => {
        // console.log(asnData?.auction_id , asnData?.auction_item_id , asnData?.warehouse_id , asnData?.donor_id , asnData?.expected_delivery , asnData?.expected_quantity, asnData?.sku, "bla bla bal")
        const product_name = data?.productsRes?.find(f=>f.sku === asnData?.sku);
        const asnItem = []
        asnItem.push({
            auction_id:asnData?.auction_id,
            auction_item_id:asnData?.auction_item_id,
            warehouse_id:asnData?.warehouse_id,
            donor_id:asnData?.donor_id,
            expected_delivery:asnData?.expected_delivery,
            expected_quantity:asnData?.expected_quantity,
            sku: product_name?.sku,

            product_name:`${product_name?.sku}, ${product_name?.name}`,
            warehouse_name:data?.warehouseReq?.find(f=>f.id === Number(asnData?.warehouse_id))?.display_name,
            donor_name:`${data?.donorsReq?.find(f=>f.id === Number(asnData?.donor_id))?.contact_firstname}`+" "+`${data?.donorsReq?.find(f=>f.id === Number(asnData?.donor_id))?.contact_lastname}`,
            store_item_id: asnData?.store_item_id
        });
        // setItems(asnItem);
        if(forAllSentData) {
            let dupliateFlag = false
            if(updateAllAsnSentItems?.length) {
                for(let obj of updateAllAsnSentItems) {
                    if(obj?.sku === product_name?.sku) dupliateFlag = true;
                }
                if(!dupliateFlag) setUpdateAllAsnSentItems(prevState => [...prevState, ...asnItem]);
            }
            else {
                setUpdateAllAsnSentItems(prevState => [...prevState, ...asnItem]);
            }
            return;
        }
        updateAsnItemsQuantities(asnItem)
      }

      const sendDataChange = (name, value, sku) => {
          const newSentData = updateAllAsnSentItems?.map((item) => {
                if(item.sku === sku) {
                    item[name] = value
                }
                return item
            })
            setUpdateAllAsnSentItems(newSentData)
      }

      const updateAllClicked = () => {
          setUpdateAllAlert({...updateAllAlert, value:true})
        }
      const confirmUpdateAll = () => {
          updateAsnItemsQuantities(updateAllAsnSentItems, true)// true is for checking if it clicks from update all
      }

    const handleAddAllClicked = async() => {
      try {
        let allClickedAsnData = {}
        const resultArray = []
        if(!data?.productsRes?.length) return;
        setAddAllClicked(!addAllClicked)
        if(addAllClicked) {
            setItems([])
            return
        }
        setLoading(true)
        // setLimitAndPage(1, 100000)
         for(let item of data?.productsRes) {
            allClickedAsnData = {
                auction_item_id: item?.id,
                warehouse_id: item?.saved_warehouse_id ? item?.saved_warehouse_id : data?.warehouseReq?.find((item, idx) => item?.display_name === 'Las Vegas')?.id, /// for sent items we have warehouse_id already saved in asn table so we use that
                donor_id: item?.donorData?.id ? item?.donorData?.id : null ,
                expected_delivery: item?.saved_expected_delivery ? new Date(item?.saved_expected_delivery).toISOString() : new Date(new Date().getTime()+7*24*60*60*1000).toISOString(), // adding 7 days to the current date as default  /// for sent items we have expected delivery already saved in asn table so we use that
                expected_quantity: item?.received_asn_quantity ? item?.received_asn_quantity : item?.actual_qty, /// received_asn_quantity is the quantity which is created in asn table while making items sent and actual_quantity is the quantity in items table.
                sku:item?.sku,
                created: moment(item?.created).format("YYYY-MM-DDTHH:mm"),
                store_item_id: item?.store_item_id
            }
            const itemsArray = addItems(allClickedAsnData, true)// for returning item
            if(itemsArray !== undefined) {
                resultArray.push(itemsArray[0])
            }
        }
        setLoading(false)
        setItems(resultArray)

      }
      catch(error) {
        setLoading(false)
      }
    }

    const handleChangeAfterAllSelected = (name, value, sku) => {
        const newItems = items?.map((obj) => {
            if(obj?.sku === sku) {
                obj[name] = value
            }
            return obj
        })
        setItems(newItems)
    }

      useEffect(() => {
        if(items?.length === (data?.productsRes?.length - noDonorIdItems) && items?.length !== 0) {
            /// to make the add all button to remove all state . If the user manually click on all items one by one.
            setAddAllClicked(true)
        }
      },[items])

      const batchASNProcess = async(file) => {
        if(!file) return setAlert(constants.ADMIN_COPY_PLEASE_CHOOSE_FILE)
        setFileUplaodStatus('loading')
        try {
            const formData = new FormData();
            formData.append("asnBatchFile",file)
            const response = await auctionServices.updateAsnAndQuantityByFileUpload(formData)
            setFileUplaodStatus('success')
            setAlert(response?.msg || constants.ADMIN_COPY_ASN_FILE_UPLOADED_SUCESS)
        }catch(error) {
            setAlert(constants.ADMIN_COPY_SOMETHING_WENT_WRONG)
            setFileUplaodStatus('failed')
        }
      }

      return (
        <>
         {loading ? 
         <Container style={{position: "relative"}} title='Schedule Incoming Donation'>
             <LoadingModal isOpen={loading} />
         </Container> : (
              <Container title='Schedule Incoming Donation'>
              {!permission ? <AccessDenied /> : (
            //       <>
            //     <div className="asn-container">
            //     <div className="asn-container-left">
            //     <div style={{display: "flex", alignItems: "center", gap: "6px"}}> 
            //      <CalendarMonthOutlinedIcon sx={{fontSize: "18px", color: "lightblue"}} />
            //      <div style={{fontSize: "14px"}}>Add Event</div>
            //      </div>
            //      <hr />
            //      <div className="asn-form-conatiner">
            //      {data?.error&&<p style={{marginBottom:'30px',textAlign:'center',color:data?.ErrorColor?data?.ErrorColor:'red'}}>{data?.error}</p>}
            //      <Select
            //         name='auction_id'
            //         label='Events'
            //         placeholder="Select one"
            //         value={data?.auction_id}
            //         required={true}
            //         disabled={Boolean(data?.auction_id)}
            //         Options={()=>data?.eventsReq?.length && data?.eventsReq?.map((i,idx)=>
            //             {
            //                 if(i.status === 'live' || i.status === 'building') {
            //                     return <option value={i.id} key={idx}>
            //                                 {i.title}
            //                             </option>
            //                 }
            //                 return null
            //             }
            //         )}
            //         onchange={handleChange}
            //     />
            //     <Select
            //         name='auction_item_id'
            //         label='Product'
            //         placeholder="Select one"
            //         value={data?.auction_item_id}
            //         required={true}
            //         disabled={Boolean(data?.auction_item_id)}
            //         Options={()=>data?.productsRes?.length && data?.productsRes?.map((i,idx)=>
            //             <option value={i.id} key={idx}>
            //                 {i.sku}, {i.name}
            //             </option>
            //         )}
            //         onchange={handleChange}
            //     />
            //     <Select
            //         name='warehouse_id'
            //         label='Warehouse'
            //         placeholder="Select one"
            //         value={data?.warehouse_id}
            //         disabled={Boolean(data?.warehouse_id)}
            //         required={true}
            //         Options={()=>data?.warehouseReq?.length && data?.warehouseReq?.map((i,idx)=>
            //             <option value={i.id} key={idx}>
            //                 {i?.display_name}
            //             </option>
            //         )}
            //         onchange={handleChange}
            //     />
            //     <Select
            //         name='donor_id'
            //         label='Supplier'
            //         placeholder="Select one"
            //         value={data?.donor_id}
            //         disabled={Boolean(data?.donor_id)}
            //         required={true}
            //         Options={()=>data?.donorsReq&&data.donorsReq.map((i,idx) => 
            //             <option value={i.id} key={idx}>
            //                 {i.contact_firstname} {i.contact_lastname} - {i.org_name},
            //             </option>
            //         )}
            //         onchange={handleChange}
            //     />
            //     <DateInp
            //         name='expected_delivery'
            //         label='Expected Delivery'
            //         required={true}
            //         min={new Date().toISOString().split('T')[0]}
            //         value={new Date(data?.expected_delivery).toISOString().split('T')[0]}
            //         onChange={handleChange}
            //     />
            //     <Input
            //         name='expected_quantity'
            //         label='Expected Quantity'
            //         placeholder='Qty here...'
            //         type='number'
            //         value={data?.expected_quantity}
            //         required={true}
            //         onchange={handleChange}
            //         onWheel={(e) => e.target.blur()}
            //     />
            //   <div className='aitem-submit-container'>
            //          <button className='aitem-modal-btn'  onClick={addItems} type='button'>ADD</button>
            //      </div>

            //      </div>
            //     </div>
            //     <div className="asn-container-right">
            //         <div style={{display: "flex", alignItems: "center", gap: "6px"}}> 
            //      <CalendarMonthOutlinedIcon sx={{fontSize: "18px", color: "lightblue"}} />
            //      <div style={{fontSize: "14px"}}>Events</div>
            //      </div>
            //      <hr />
            //      <Paper>
            //     <TableContainer>
            //         <Table sx={{ minWidth: 600 }} aria-label="customized table">
            //         <TableHead>
            //             <TableRow>
            //                 <StyledTableCell>Products</StyledTableCell>
            //                 <StyledTableCell>Warehouse</StyledTableCell>
            //                 <StyledTableCell>Supplier</StyledTableCell>
            //                 <StyledTableCell>Expected Delivery</StyledTableCell>
            //                 <StyledTableCell>Expected Quantity</StyledTableCell>
            //             </TableRow>
            //         </TableHead>
            //         <TableBody>
            //         {
            //             items.length ? items.map((i,idx)=>
            //                 <StyledTableRow key={idx}>
            //                 <StyledTableCell>{i?.product_name}</StyledTableCell>
            //                 <StyledTableCell>{i?.warehouse_name}</StyledTableCell>
            //                 <StyledTableCell>{i?.donor_name}</StyledTableCell>
            //                 <StyledTableCell>{new Date(i?.expected_delivery).toDateString()}</StyledTableCell>
            //                 <StyledTableCell>{i?.expected_quantity}</StyledTableCell>
            //                 </StyledTableRow>
            //             ) : <></>
            //         }
            //         </TableBody>
            //         </Table>
            //     </TableContainer>
             
            // </Paper>
            // <div className='aitem-submit-container'>
            //          <button className='aitem-modal-btn' 
            //          disabled={items?.length == 0}  
            //          type='button'
            //          onClick={
            //             !data?.loadingAsn && items.length && data?.productsRes?.length ?
            //             async ()=>{
            //                 try {
            //                     setLoading(true)
            //                     const records = items.map(i=>({
            //                         auction_item_id: i.auction_item_id,
            //                         warehouse_id: i.warehouse_id,
            //                         donor_id: i.donor_id,
            //                         expected_quantity: i.expected_quantity,
            //                         expected_delivery: i.expected_delivery,
            //                         sku:i.sku
            //                     }));
            //                     setData({...data,loadingAsn:true});
            //                     const res = await submitAsn({records:records});
            //                     setLoading(false)
            //                     if(res?.data?.successRecords?.length) {
            //                         setData({
            //                             loadingAsn:false,
            //                             error:'Success adding asn',//Here it is success
            //                             ErrorColor:'green',
            //                             loading:false,
            //                             expected_delivery: new Date().toISOString()
            //                         });
            //                         setTimeout(()=>{
            //                             setData({
            //                                 error:'',
            //                                 ErrorColor:'',
            //                                 loading:true,
            //                                 loadingAsn:false,
            //                                 expected_delivery: new Date().toISOString()
            //                             });
            //                         },3000);
            //                         setItems([]);
            //                     } else {
            //                         setData({...data,
            //                             loadingAsn:false,
            //                             error:'Something Went Wrong'
            //                         });
            //                         setTimeout(()=>{
            //                             setData({...data,error:''});
            //                         },3000);
            //                     }
            //                 } catch(err) {
            //                     setLoading(false)
            //                     logger('./pages/components/Donation/Donation.jsx','submitAsn(): catch',err); 
            //                     setData({...data,
            //                         loadingAsn:false,
            //                         error:err.message
            //                     });
            //                     setTimeout(()=>{
            //                         setData({...data,error:''});
            //                     },3000);
            //                 }
            //             } : ()=>{}
            //         }
                     
            //          >Finish and Send</button>
            //      </div>
            //     </div>
            //     </div>
                         
            //       </>

              <>
              <div style={{gap:'200px', alignItems:'center', marginBottom:"30px"}} className='event_header_container'>
                 <span className='calender_icon_container'><CalendarMonthOutlinedIcon sx={{ fontSize: '20px', color: '#34b5e5' }} /><p>ASN</p></span>
                  {data?.eventsReq?.length > 0 ? 
                  <div className="asn_header_actions_container">
                  <DropDown 
                    onChange={(e) => {
                        setSelectedEvent(e)
                        handleChange(e, true)
                    }} 
                    options={data?.eventsReq} 
                    label={constants.ADMIN_COPY_FILTER_BY_EVENT}
                    isSearchable={true}
                    value={selectedEvent}
                /> 
                <button className="aitem-modal-btn" onClick={clearFilter}>Clear filter</button>
                  </div>
                : 'No events found'}
              </div>
              <div className='filters_section'>
              <div style={{width:'100%'}} >
              <UploadFile uploadStatus={fileUploadStatus} buttonText='Batch ASN Process' onUploadFile={batchASNProcess} />
              </div>
              <div className='filters_container'>
              {
                data?.productsRes?.length ? 
                <>
                {( showAsnSent !== 'sent') ? 
                 <Tooltip placement="left-start" title={<Typography sx={{fontSize:'0.85rem'}}>{constants.ADMIN_COPY_ADD_ALL_TOOLTIP}</Typography>}>
                    <div style={{display:'flex', alignItems:'center'}}>
                    <TableButton
                       onClick={handleAddAllClicked} 
                       backgroundColor={(addAllClicked && items?.length) ? redColor : 'white'} 
                       color={(addAllClicked && items?.length) ? 'white' : redColor} 
                       text={(addAllClicked && items?.length) ? 'Remove All' : 'Add All'} 
                       style={{paddingInline:'13px', fontSize:'0.9rem'}} 
                    />
                    </div>
                 </Tooltip>
                : 
                <Tooltip placement="left-start" title={<Typography sx={{fontSize:'0.85rem'}}>{constants.ADMIN_COPY_ADD_ALL_TOOLTIP?.replace('select', 'update')}</Typography>}>
                    <div style={{display:'flex', alignItems:'center'}}>
                    <TableButton
                       onClick={updateAllClicked} 
                       backgroundColor={'white'} 
                       color={redColor} 
                       text={'Update All'} 
                       style={{paddingInline:'13px', fontSize:'0.9rem'}} 
                    />
                    </div>
                </Tooltip>
                
                }
                </>
                :null
              }
                <CheckBoxComponent label={constants.ADMIN_COPY_ASN_FILTER_SHOW_SENT} onChange={handleShowSent} checked={showAsnSent === 'sent'} value={showAsnSent} />
              </div>
              </div>
              <Paper sx={{minHeight:'400px'}} >
                 { !data?.productsRes?.length
                    ? <p style={{textAlign:'center', paddingTop:"10%"}}>{constants.ADMIN_COPY_ASN_NO_PRODUCTS}</p>
                    : <TableContainer>
                   <Table sx={{ minWidth: 700 }} aria-label="customized table">
                       <TableHead>
                          <TableRow>
                              {/* <StyledTableCell>Select Product</StyledTableCell> */}
                              <StyledTableCell >Products</StyledTableCell>
                              <StyledTableCell>Donor</StyledTableCell>
                              {
                                (!selectedEvent?.value || selectedEvent?.eventType === 'Marketplace') && 
                                <StyledTableCell>Marketplace</StyledTableCell>
                              }
                              <StyledTableCell>Created Date</StyledTableCell>
                              <StyledTableCell>Select warehouse</StyledTableCell>
                              <StyledTableCell>Delivery Date</StyledTableCell>
                              <StyledTableCell>{ showAsnSent === 'sent' ? 'Qty Received' : 'Enter quantity'}</StyledTableCell>
                              <StyledTableCell>Actual current qty</StyledTableCell>
                              <StyledTableCell>Add Item</StyledTableCell>
                          </TableRow>
                      </TableHead>
                      <TableBody>
                    {data?.productsRes?.length && data?.productsRes?.map((item, index) => {
                         return (
                        <AsnTableRow
                          data={data}
                          index={index}
                          item={item}
                          addItems={addItems}
                          key={index}
                          removeItem={removeItem}
                          items={items}
                          createUpdateItem={createUpdateItem}
                          setNoDonorIdItems={setNoDonorIdItems}
                          noDonorIdItems={noDonorIdItems}
                          sendDataChange={sendDataChange}
                          handleChangeAfterAllSelected={handleChangeAfterAllSelected}
                          addAllClicked={addAllClicked}
                          setItems={setItems}
                          removeItemsSkuArray={removeItemsSkuArray}
                          selectedEvent={selectedEvent}
                        />
                            )
                        })
                        }
                      </TableBody>
                   </Table>
                 <TablePagination
                        // rowsPerPageOptions={[18, 24]}
                        rowsPerPageOptions={[20,50, 100, 250,350, 500, 1000, 100000]}
                        component="div"
                        count={totalProducts}
                        rowsPerPage={limit}
                        page={page - 1}
                        onPageChange={(e, newPage) => {
                            setPage(newPage + 1)
                        }}
                        onRowsPerPageChange={(e) => {
                            setLimit(+e.target.value);
                            setPage(1);
                         }}
                    />
                { showAsnSent !== 'sent' &&/// not showing the button if the data is showing asn sent
                    <div className='aitem-submit-container'>
                     <button className='aitem-modal-btn' 
                     disabled={items?.length == 0}  
                     type='button'
                     onClick={
                        !data?.loadingAsn && items.length && data?.productsRes?.length ?
                        async ()=>{
                            try {
                                setLoading(true)
                                // const records = items.map(i=>({
                                //     auction_item_id: i.auction_item_id,
                                //     warehouse_id: i.warehouse_id,
                                //     donor_id: i.donor_id,
                                //     expected_quantity: i.expected_quantity,
                                //     expected_delivery: i.expected_delivery,
                                //     sku:i.sku
                                // }));
                                
                                setData({...data,loadingAsn:true});
                                let res;
                                if(showAsnSent) {
                                    res = await updateAsnItemsQuantities(items)/// for now this condition will not work because we are not using this button for updating asn status of sent items
                                    return
                                }else {
                                    res = await submitAsn({records:items});
                                }
                                setLoading(false)
                                setAddAllClicked(false)
                                if(page === 1 && limit === 20) {
                                    getPageData();
                                }
                                else {
                                    setLimitAndPage(1, 20)
                                }
                                setRemoveItemsSkuArray([])
                                if(res?.data?.length) {
                                    setData({
                                        ...data,
                                        loadingAsn:false,
                                        error:'Success adding asn',//Here it is success
                                        ErrorColor:'green',
                                        loading:false,
                                        expected_delivery: new Date().toISOString()
                                    });
                                    setItems([]);
                                    setAlert(constants.ADMIN_COPY_ASN_CREATED_ALERT)
                                    // setTimeout(()=>{
                                    //     setData({
                                    //         error:'',
                                    //         ErrorColor:'',
                                    //         loading:true,
                                    //         loadingAsn:false,
                                    //         expected_delivery: new Date().toISOString()
                                    //     });
                                    // },3000);
                                } else {
                                    setData({...data,
                                        loadingAsn:false,
                                        error:'Something Went Wrong'
                                    });
                                    setItems([]);
                                    setAlert(constants.ADMIN_COPY_ASN_CREATION_ERROR)
                                    // setTimeout(()=>{
                                    //     setData({...data,error:''});
                                    // },3000);
                                }
                            } catch(err) {
                                setLoading(false)
                                logger('./pages/components/Donation/Donation.jsx','submitAsn(): catch',err); 
                                setData({...data,
                                    loadingAsn:false,
                                    error:err.message
                                });
                                setItems([]);
                                setAlert(constants.ADMIN_COPY_ASN_CREATION_ERROR)
                                // setTimeout(()=>{
                                //     setData({...data,error:''});
                                // },3000);
                            }
                        } : ()=>{}
                    }
                     
                     >Finish and Send</button>
                 </div>
                }
                 </TableContainer>}
              </Paper>
              </>
              )}
          </Container>
         )}
         <Alert isOpen={! !alert} message={alert}
                onClose={() => setAlert(null)} />
           <Alert isOpen={updateAllAlert.value} message={updateAllAlert.message}
                type='confirm'
                onClose={() => setUpdateAllAlert({...updateAllAlert, value:false})} 
                onConfirm={confirmUpdateAll}
            />
        </>
       )
};

export default Asn;