import React, { useState, useEffect,useContext } from 'react';
import { useSelector,useDispatch } from 'react-redux';
import moment from 'moment';
import CalendarMonthOutlinedIcon from '@mui/icons-material/CalendarMonthOutlined';
import Container from '../../components/HOC/Container/Container';
import { useNavigate } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { TablePagination } from '@mui/material';
import BorderColorIcon from '@mui/icons-material/BorderColor';
import './order.css'
import orderService from '../../../services/order-services';
import DropDown from '../../components/DropDown/DropDown';
import {addOrders,chooseEvent,setPageDetails} from '../../../redux/orderReducer';
import authService from '../../../services/auth-services';
import AccessDenied from '../../components/AccessDenied/AccessDenied';
import { fetchEvents } from '../../../redux/eventReducer';
import AuthContext from '../../../store/auth-context';
import LoadingModal from '../../components/LoadingModal/LoadingModal';



const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        fontWeight: 600,
        borderBottom: "1px solid black"
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
    },
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}));

function Order() {
    const navigate = useNavigate()
    const dispatch=useDispatch()
    const ctx = useContext(AuthContext)
    
    const [options,setOptions]=useState([{label:'',value:''}])
    const [access, setAccess] = useState(true)
    const {events, loading} = useSelector(state => state.userEvents)
    const orders=useSelector(state=>state.orderDetails.orders)
    const totalOrders=useSelector(state=>state.orderDetails.totalOrders)
    const selectedEvent=useSelector(state=>state.orderDetails.selectedEvent)
    const page=useSelector(state=>state.orderDetails.page)
    const limit=useSelector(state=>state.orderDetails.limit)
    
    const [isLoading, setIsLoading] = useState(false)

    useEffect(() => {
        dispatch(fetchEvents({user_id: ctx.user.id}))
    }, [])

    useEffect(() => {
       const data= events?.map((event)=>({label:event.title,value:event.id}))
       setOptions(data)
    }, [events])

    useEffect(() => {
        setAccess(true)
        selectedEvent.value && fetchOrders(selectedEvent.value)
    }, [limit, page,selectedEvent.value])

    const fetchOrders = async (auctionId) => {
        setIsLoading(true)
          try {
            const permission = await authService.checkPermissions(11, auctionId)
            if(!permission.access) {
              setIsLoading(false)
              setAccess(false)
              return
            } else {
                setAccess(true)
            }
            let data = await orderService.getAllOrder(auctionId,page, limit)
            dispatch(addOrders({orders:data?.rows,totalOrders:data?.count}))
            setIsLoading(false)
          } catch (error) {
            setIsLoading(false)
          }
        
    }
    const handleEdit = (xrefId) => {
        navigate(`/admin/edit-order/${xrefId}`)
    }

    return (
        <Container title='Orders'>
            { loading ? <LoadingModal isOpen={true} /> :
                <>
            <div className='event_header_container'>
                <span className='calender_icon_container'><CalendarMonthOutlinedIcon sx={{ fontSize: '20px', color: '#34b5e5' }} /><p>Choose an Event</p></span>
            </div>
                <DropDown isSearchable onChange={(e) =>dispatch(chooseEvent({label:e.label,value:e.value}))} value={selectedEvent}  options={options}/>
            <hr />
            {!access ? <div style={{height: "300px"}}><AccessDenied /></div> :
             isLoading ? <LoadingModal style={{ position: 'relative', width: '100%', marginLeft: '0px', minHeight: '300px', backgroundColor: 'white' }} isOpen={true} /> :
             orders?.length?<Paper>
                <TableContainer>
                    <Table sx={{ minWidth: 700 }} aria-label="customized table">
                        <TableHead>
                            <TableRow>
                                <StyledTableCell >Order number</StyledTableCell>
                                <StyledTableCell>Product</StyledTableCell>
                                <StyledTableCell>SKU</StyledTableCell>
                                <StyledTableCell>Status</StyledTableCell>
                                <StyledTableCell>Purchase date</StyledTableCell>
                                {/* <StyledTableCell>Tracking url</StyledTableCell> */}
                                <StyledTableCell sx={{fontSize: 12}}>Price (excl. tax and charges)</StyledTableCell>
                                <StyledTableCell>Quantity</StyledTableCell>
                                <StyledTableCell>Manage</StyledTableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {orders?.map((row, i) => {
                                let status = ''
                                switch (row.user_order?.status) {
                                    case '0':
                                        status = 'Order Placed'
                                        break;
                                    case '1':
                                        status = 'Order Placed'
                                        break;
                                    case '3':
                                        status = 'Shipped'
                                        break;
                                    case '6':
                                        status = 'Shipped'
                                        break;
                                    case '7':
                                        status = 'Out For Delivery'
                                        break;
                                    case '8':
                                        status = 'Delivered'
                                        break;
                                    case '9':
                                        status = 'Packed'
                                        break;
                                    case '12':
                                        status = 'In Transit'
                                        break;
                                    case '14':
                                        status = 'Returned'
                                        break;
                                    case '15':
                                        status = 'Address Check Failed'
                                        break;
                                    case null:
                                        status = 'Payment Failed'
                                        break;
                                    default:
                                        status = 'Unknown'
                                        break;
                                }

                                return <StyledTableRow key={row.id}>
                                    <StyledTableCell>BG_{row.user_order?.id}</StyledTableCell>
                                    <StyledTableCell>{row.auction_item?.name}</StyledTableCell>
                                    <StyledTableCell>{row.product_sku_id}</StyledTableCell>
                                    <StyledTableCell>{(row.user_order?.payment_status === "failed") ? "Payment Failed" : status}</StyledTableCell>
                                    <StyledTableCell>{moment(row.user_order?.created).format('MM/DD/YYYY')}</StyledTableCell>
                                    {/* <StyledTableCell>{}</StyledTableCell> */}
                                    <StyledTableCell>{row.auction_item?.price == 0 ? (parseFloat(row.auction_item?.store_item?.price) * row.quantity_bought) : row.user_order?.subtotal}</StyledTableCell>
                                    <StyledTableCell>{row.quantity_bought}</StyledTableCell>

                                    <StyledTableCell>
                                        <div onClick={() => handleEdit(row.id)} className='table-btn'> <BorderColorIcon sx={{ fontSize: "16px", color: "#5EABD6" }} /> <span style={{ color: "#5EABD6", fontWeight: "600" }}>Edit</span></div>
                                    </StyledTableCell>

                                </StyledTableRow>
                            })}
                        </TableBody>
                    </Table>
                </TableContainer>
                <TablePagination
                    rowsPerPageOptions={[5, 10, 20]}
                    component="div"
                    count={totalOrders}
                    rowsPerPage={limit}
                    page={page - 1}
                    onPageChange={(e, newPage) =>dispatch(setPageDetails({page:newPage + 1}))}
                    onRowsPerPageChange={(e) => {dispatch(setPageDetails({page:1,limit:+e.target.value}))}}
                />
            </Paper>:<Paper sx={{height:'300px',textAlign:'center',paddingY:'140px'}}>
                {selectedEvent.value ? <p>No order associated with this event</p>:<p>Please choose an event</p>}</Paper>}
                </>}
        </Container>
    )
}

export default Order